import type { PaymentType } from 'models/api';
import { is } from 'utils/helpers';

import type { PaymentComponentState } from './payment';

/**
 * Payment types that need data from the Adyen web components when making the
 * payment request.
 */
export const PAYMENT_TYPES_WITH_COMPONENT_STATE = ['blik', 'scheme'] as const;

export type PaymentComponentPaymentType =
	(typeof PAYMENT_TYPES_WITH_COMPONENT_STATE)[number];

export function isPaymentTypeWithComponentState(
	paymentType: PaymentType | undefined,
): paymentType is PaymentComponentPaymentType {
	return Boolean(
		paymentType && is.oneOf(paymentType, ...PAYMENT_TYPES_WITH_COMPONENT_STATE),
	);
}

export function isPaymentReadyForCheckout(
	paymentType: PaymentType | undefined,
	paymentComponentState: PaymentComponentState | undefined,
): boolean {
	// Fully paying with a coupon can mean there is no payment type. Ensuring
	// selection of a payment type is handled by backend.
	if (!paymentType || !isPaymentTypeWithComponentState(paymentType)) {
		return true;
	}
	return Boolean(paymentComponentState?.isValid);
}
