// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconSearch(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M21.6 20.182 16.314 14.9a8.019 8.019 0 1 0-1.414 1.414l5.282 5.286a1 1 0 0 0 1.418-1.418M4 10a6 6 0 1 1 6 6 6.007 6.007 0 0 1-6-6" />
		</svg>
	);
}
IconSearch.displayName = 'IconSearch';
