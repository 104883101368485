import { useEffect, useRef } from 'react';

/**
 * Get the value of somehting from the previous render.
 */
export function usePrevious<T>(value: T): T | undefined {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}
