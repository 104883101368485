import { type EffectCallback, useEffect, useRef } from 'react';

import { is } from 'utils/helpers';

import { usePrevious } from './usePrevious';

/**
 * Run an effect when a value changes and get the previous value in the
 * callback.
 *
 * The effect only runs if there is a previous, defined value that's different
 * so it won't run on initial render.
 *
 * @example
 *
 * function Component({ someProp }) {
 *   useValueChangeEffect(someProp, (prevSomeProp) => {
 *     // Runs when the someProp value changes
 *   });
 *
 *   return null;
 * }
 */
export function useValueChangeEffect<T>(
	value: T,
	effect: (previousValue: T, currentValue: T) => ReturnType<EffectCallback>,
) {
	// Keep the effect callback from the current render. Don't want to use it
	// directly as a dependency to the 'real' effect since it will then trigger
	// on every render.
	const savedEffect = useRef<typeof effect>(effect);
	useEffect(() => {
		savedEffect.current = effect;
	}, [effect]);

	const prevValue = usePrevious(value);
	useEffect(() => {
		if (!is.undefined(prevValue) && prevValue !== value) {
			return savedEffect.current(prevValue, value);
		}
		return undefined;
	}, [value, prevValue]);
}
