import { useEffect, useRef } from 'react';
import type { RefObject } from 'react';

/**
 * Use a ref passed via React.forwardRef inside the receiving component.
 *
 * @example
 *
 * const MyLink = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
 *   const linkRef = useForwardedRef<HTMLAnchorElement>(ref);
 *   useEffect(() => {
 *     console.log('Link points to', linkRef.current?.href);
 *   }, []);
 *   return <a ref={linkRef} {...props}>...</a>;
 * })
 */
export function useForwardedRef<T>(
	ref: React.ForwardedRef<T>,
	initialValue: any = null,
): RefObject<T> {
	const targetRef = useRef<T>(initialValue);

	useEffect(() => {
		if (!ref) {
			return;
		}

		if (typeof ref === 'function') {
			ref(targetRef.current);
		} else {
			ref.current = targetRef.current;
		}
	}, [ref]);

	return targetRef;
}
