import type { State } from 'xstate';

import type { Context } from './contactReferences.types';

export const selectContactReferences = (current: State<Context>) =>
	current.context.contactReferences;

export const selectSelectedContactReference = (current: State<Context>) =>
	current.context.selectedContactReference;

export const selectErrors = (current: State<Context>) => current.context.errors;
