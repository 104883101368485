import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

import type {
	Cart,
	CreditSimulation,
	FaqSearchItem,
	ProductQuestions,
	RelatedProducts,
	RelationType,
	ReviewResponse,
} from 'models/api';
import { ItemStockResponse } from 'models/api';
import { ResursBankPaymentSignage } from 'models/api/resusBankPriceSignage';
import { ProductResponse } from 'models/api/variant';
import type { ProductCard } from 'models/productCard';
import type { Store } from 'models/store';
import { fetchStock } from 'state-machines/stock';
import fetchData, { API_URL } from 'utils/fetchData';
import { empty } from 'utils/helpers';
import { createUrl } from 'utils/url';

import { usePagedData } from './internal';

export function usePopularProducts({
	category,
	isIdle,
	itemLimit,
}: { category?: string; isIdle?: boolean; itemLimit?: number } = {}) {
	const params = {
		'page-size': itemLimit || 5,
		'categoryId': category,
	};
	const { data, error, isLoading } = useSWRImmutable(
		isIdle ? null : createUrl(`${API_URL}Search/popularproducts`, params),
		fetchData<ProductCard[]>,
	);

	return { products: data || empty.array, isLoading, error };
}

export function usePopularSearchTerms({
	isIdle,
	itemLimit,
}: { isIdle?: boolean; itemLimit?: number } = {}) {
	const { data, error, isLoading } = useSWRImmutable(
		isIdle ? null : `${API_URL}Search/popularterms`,
		fetchData<string[]>,
	);

	return {
		terms: data ? data.slice(0, itemLimit || 999) : empty.array,
		isLoading,
		error,
	};
}

export function useCreditSimulation(price: number) {
	const { data, error, isLoading } = useSWRImmutable(
		`${API_URL}Customer/CreditSimulation?price=${price}`,
		fetchData<CreditSimulation>,
	);

	return { creditSimulationData: data, isLoading, error };
}

export function useFaqSearch(
	query: string,
	{
		includeContent = false,
		limit = 0,
	}: { includeContent?: boolean; limit?: number } = {},
) {
	const { data, error, isLoading } = useSWRImmutable(
		query
			? createUrl(`${API_URL}FaqSearch/${encodeURIComponent(query)}`, {
					numberOfResults: limit || null,
					includeContent: includeContent ? 'true' : null,
				})
			: null,
		fetchData<FaqSearchItem[]>,
	);

	return { items: data || empty.array, isLoading, error };
}

export function useAllStores(isActive: boolean = true) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive ? `${API_URL}Stores` : null,
		fetchData<Store[]>,
	);

	return { stores: data || empty.array, isLoading, error };
}

export function useNearbyStores(
	storeId: string | undefined,
	isActive: boolean = true,
) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive && storeId
			? createUrl(`${API_URL}Stores/nearbyStores`, { storeId })
			: null,
		fetchData<Store[]>,
	);

	return { stores: data || empty.array, isLoading, error };
}

export function useProductStoreStocks(productId: string | undefined) {
	const { data, error, isLoading } = useSWRImmutable(
		// Not using a URL as key here so add the hook name to ensure we never get
		// cache hits from anything else.
		productId ? [productId, 'useProductStoreStocks'] : null,
		([id]) => fetchStock(id),
	);

	return {
		stocks: data?.[0]?.storeStock?.stocks || empty.array,
		isLoading,
		error,
	};
}

export function useSWRMutate(key: string) {
	const { mutate } = useSWRConfig();
	return useCallback(() => mutate(key), [key, mutate]);
}

export function useOrderInformation(cartId: string | undefined) {
	const { data, error, isLoading } = useSWRImmutable(
		cartId ? `${API_URL}Cart/${cartId}` : null,
		fetchData<Cart>,
	);

	return { orderInformation: data, isLoading, error };
}

export function useReviews(
	productId: string,
	reviewScore: number | undefined,
	reviewSorting: string | undefined,
) {
	return usePagedData<ReviewResponse, 'reviews'>({
		baseUrl: createUrl(`${API_URL}Reviews/${productId}`, {
			reviewScore,
			reviewSorting,
		}),
		itemsKey: 'reviews',
	});
}

export function useFilteredReviews({
	filter = 'HasImage',
	productId,
	reviewScore,
}: {
	filter: 'HasImage';
	productId: string;
	reviewScore?: 1 | 2 | 3 | 4 | 5 | undefined;
}) {
	// TODO: page-size should be what?
	const { data, error, isLoading } = useSWRImmutable(
		createUrl(`${API_URL}Reviews/${productId}`, {
			filter,
			pageSize: 100,
			reviewSorting: 'ScoreDesc',
			reviewScore,
		}),
		fetchData<ReviewResponse>,
	);
	return {
		reviewResponse: data,
		isLoading,
		error,
	};
}

export function useRelatedProducts({
	isActive,
	pageSize = 15,
	productId,
	relationType,
}: {
	isActive: boolean;
	pageSize?: number;
	productId: string;
	relationType: RelationType;
}) {
	return usePagedData<RelatedProducts, 'variants'>({
		baseUrl: createUrl(`${API_URL}Variant/${productId}/relatedproducts`, {
			relationType,
		}),
		itemsKey: 'variants',
		isActive,
		pageSize,
	});
}

export function useCampaignProducts(
	campaignId: string | undefined,
	isActive: boolean,
) {
	return usePagedData<RelatedProducts, 'variants'>({
		baseUrl: `${API_URL}Campaign/${campaignId}`,
		itemsKey: 'variants',
		isActive,
		pageSize: 15,
	});
}

export function useProductQuestions(productId: string) {
	return usePagedData<ProductQuestions, 'questions'>({
		baseUrl: `${API_URL}ProductQuestions/${productId}`,
		itemsKey: 'questions',
	});
}
export function useResursBankPriceSignage(
	paymentMethodIdProvider: string | undefined,
	amount: number | undefined,
	isActive: boolean,
) {
	return useSWRImmutable(
		isActive && paymentMethodIdProvider && amount
			? createUrl(`${API_URL}payment/resursbank/price-signage`, {
					paymentMethodIdProvider,
					amount,
				})
			: null,
		fetchData<ResursBankPaymentSignage>,
	);
}
export function useProductVariant(productId: string) {
	return useSWRImmutable(
		productId ? `${API_URL}Variant/${productId}` : null,
		fetchData<ProductResponse>,
	);
}

export function useChatAvailability(isActive: boolean) {
	const { data, error, isLoading } = useSWR(
		isActive ? `${API_URL}CustomerService/availability` : null,
		fetchData<{
			available: boolean;
			open: boolean;
			zendeskJwt: string | undefined;
		}>,
	);

	return {
		isAvailable: Boolean(data?.available),
		isOpen: Boolean(data?.open),
		zendeskJwt: data?.zendeskJwt,
		isLoading,
		error,
	};
}

export function useStock({
	isActive,
	storeIds,
	variantIds,
}: {
	isActive: boolean;
	storeIds: string | string[] | undefined;
	variantIds: string | string[] | undefined;
}) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive
			? createUrl(`${API_URL}ProductStock`, { storeIds, variantIds })
			: null,
		(key) => {
			// the key here is used to have a sane way to get params that need to be in the body
			// into the fetcher, therefore we need to rebuild the request url
			const url = new URL(key);
			const variantIds = url.searchParams.getAll('variantIds');
			url.searchParams.delete('variantIds');
			return fetchData<ItemStockResponse[]>(url.toString(), {
				method: 'POST',
				body: JSON.stringify(variantIds ?? []),
			});
		},
	);

	return { itemsStock: data, isLoading, error };
}
