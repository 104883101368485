export function openPageInLayoutservice() {
	const path = window.location.pathname;
	const origin = encodeURI(window.location.origin);
	const url = `/api/dev/open-page-in-layoutservice?path=${path}&origin=${origin}`;

	window.open(url, '_blank');
}

export function layoutServiceUrlViewer() {
	if (typeof document === 'undefined') {
		return;
	}

	const keystrokeDelay = 400;
	let numKeyPresses = 0;
	let lastKeyTime = Date.now();

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			const currentTime = Date.now();
			if (currentTime - lastKeyTime > keystrokeDelay) {
				numKeyPresses = 0;
			} else {
				numKeyPresses++;
			}
			lastKeyTime = currentTime;

			if (numKeyPresses >= 2) {
				openPageInLayoutservice();
			}
		}
	});
}
