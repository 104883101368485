import { useCallback, useState } from 'react';

import { StoredFileResponse } from 'models/api/userFileStorage';
import fetchResult from 'utils/fetchResult';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export function useFileUpload({ url }: { url: string }) {
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState<string[] | undefined>(undefined);
	const [uploadedFiles, setUploadedFiles] = useState<StoredFileResponse[]>([]);
	const { t } = useI18n();

	const uploadFile = useCallback(async (file: File | undefined) => {
		if (!file) {
			// setError('No file selected'); ?
			return;
		}
		const formData = new FormData();
		formData.append('file', file);
		setIsLoading(true);

		const res = await fetchResult<StoredFileResponse>(url, {
			method: 'POST',
			body: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'accept': 'application/json',
			},
		});
		setIsLoading(false);
		if (res.isOk()) {
			setUploadedFiles([res.value]);
		}
		if (res.isErr()) {
			if (res.error.status === 413) {
				setErrors([t('FileSizeTooLarge')]);
			} else {
				const validationErrors = res.error.fieldValidationErrors
					? Object.values<string[]>(res.error.fieldValidationErrors).flat()
					: [];
				setErrors([
					...validationErrors,
					...(res.error.businessLogicErrors?.map((error) => t(error.key)) ??
						[]),
				]);
			}
		}
	}, []);

	return {
		uploadFile,
		uploadedFiles,
		isLoading,
		errors,
		hasError: is.arrayWithLength(errors),
	};
}
