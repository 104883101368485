import { type Reducer, useCallback, useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';

import type { BasePaginatedResponse } from 'models/api';
import { TupleSet } from 'utils/collection';
import { assertUnreachable, is } from 'utils/helpers';

import { useLayoutServicePlaceholder } from './useLayoutServicePlaceholder';

interface Params<ItemT, InitialComponentT, ItemKeyT> {
	/** The standard page size for this view, regardless of what's currently displayed. */
	defaultPageSize?: number;
	/** Negative offset for paginated items on the first page, if some other content is intermixed with them. */
	firstPageItemCountOffset?: number;
	/** Initial data for the `component` return field, before any pagination request has been made. */
	initialComponent?: InitialComponentT;
	/** Initial data for the `items` return field, before any pagination request has been made. */
	initialItems?: ItemT[];
	/** Initial item offset to the next page. */
	initialNextPageOffset: number;
	/** Initial query var key-value pairs. */
	initialQueryVars?: [string, string][];
	/** Object key in the `placeholderComponentName` that has the array of items. */
	itemsKey: ItemKeyT;
	/** Query parameter name used to set pagination offset. */
	offsetQueryVarName: string;
	/** Query parameter name used to set pagination page size. */
	pageSizeQueryVarName: string;
	/** Object key in layout service data for the relevant component. */
	placeholderComponentName: string;
}

type QueryVars = TupleSet<string, string>;
interface State<ItemT> {
	firstPageItemCountOffset: number;
	futureNextPageOffset: number;
	initialNextPageOffset: number;
	isActive: boolean;
	items: ItemT[];
	itemsUpdateType: 'append' | 'replace';
	nextPageOffset: number | null;
	queryVars: QueryVars;
}
type Action<ItemT> =
	| { type: 'LOAD_NEXT_PAGE'; isFirstPageOffsetActive: boolean }
	| {
			type: 'SET_ITEMS';
			futureNextPageOffset?: number;
			isFirstPageOffsetActive?: boolean;
			items: ItemT[];
	  }
	| { type: 'UPDATE_QUERY_VARS'; queryVars: QueryVars };

function reducer<ItemT>(
	state: State<ItemT>,
	action: Action<ItemT>,
): State<ItemT> {
	switch (action.type) {
		case 'LOAD_NEXT_PAGE':
			if (state.futureNextPageOffset === state.nextPageOffset) {
				return state;
			}
			return {
				...state,
				nextPageOffset: action.isFirstPageOffsetActive
					? state.futureNextPageOffset + state.firstPageItemCountOffset
					: state.futureNextPageOffset,
				isActive: true,
				itemsUpdateType: 'append',
			};

		case 'SET_ITEMS':
			return {
				...state,
				futureNextPageOffset:
					action.futureNextPageOffset ?? state.futureNextPageOffset,
				items:
					state.itemsUpdateType === 'append'
						? [
								// An append update means an additional page has been loaded.
								// If there is a first page offset for the list the offsetted
								// items will arrive on the following page, so slice them away
								// from the first page to avoid duplicates.
								...(action.isFirstPageOffsetActive
									? state.items.slice(0, state.firstPageItemCountOffset)
									: state.items),
								...action.items,
							]
						: action.items,
			};

		case 'UPDATE_QUERY_VARS':
			// Reset offsets to start on first page again.
			return {
				...state,
				itemsUpdateType: 'replace',
				futureNextPageOffset: state.initialNextPageOffset,
				isActive: true,
				nextPageOffset: null,
				queryVars: action.queryVars,
			};

		default:
			return assertUnreachable(action);
	}
}

/**
 * Handles filtering and pagination for a list of items that uses the
 * `hasNextPage` and `nextPageOffset` API. Requests are made to the page itself.
 *
 * Is controlled by query variables, similar to URLSearchParams, but works in
 * 'reverse' where an update to these variables will trigger a request that
 * updates the URL when done, rather than updates to the URL triggering a
 * request. The variables are updated with `updateQueryVars` and any changes
 * will be available immediately for things like `hasQueryVar` and
 * `queryVarItems`, to allow optimistic updates of the UI.
 *
 * The `component` field can be used to read things other than the items from
 * the response, like `categories` and `mainHeading` in the example below.
 * Since `component` is only fetched when a query variable has been updated
 * it's undefined on the initial page load - `initialComponent` data can be
 * passed to give `component` a fallback before any updates have been made.
 *
 * Is assumed to be used on a static page so if `initialItems` changes, the
 * hook's state will reset.
 *
 * Note that `firstPageItemCountOffset` (for things like micro content) is used
 * to slice items in multiple places.
 *
 * @example
 *
 * interface Article {
 *   title: string;
 *   author: string;
 * }
 * interface ArticleList extends BasePaginatedResponse {
 *   articles: Article[];
 *   categories: string[];
 *   mainHeading: string;
 * }
 * type InitialArticleList = Omit<ArticleList, 'articles'>
 *
 * function Articles({
 *   articles: initialArticles,
 *   categories: initialCategories,
 *   mainHeading: initialMainHeading,
 *   nextPageOffset: initialNextPageOffset,
 * }) {
 *   const {
 *     component: articleList,
 *     getQueryVarValue,
 *     hasQueryVar,
 *     isLoading,
 *     items: articles,
 *     loadMore: loadMoreArticles,
 *     updateQueryVars,
 *   } = usePagination<Article, ArticleList, InitialArticleList>({
 *     initialComponent: {
 *       categories: initialCategories,
 *       mainHeading: initialMainHeading,
 *     },
 *     initialItems: initialArticles,
 *     initialNextPageOffset,
 *     itemsKey: 'articles',
 *     offsetQueryVarName: 'articles-offset',
 *     pageSizeQueryVarName: 'article-page-size',
 *     placeholderComponentName: 'ArticlesList',
 *   });
 *   const currentCategory = getQueryVarValue('cat');
 *   const categories = articleList.categories;
 *
 *   return (
 *     <div>
 *       <h1>{mainHeading}: {currentCategory}</h1>
 *       {categories.map((category) => (
 *         <Button
 *           onClick={() => {
 *             updateQueryVars((vars) => { vars.set('cat', category) });
 *           }}
 *           isCurrent={hasQueryVar('cat', category)}>
 *           text={category}
 *         />
 *       ))}
 *       <div>{articles.map(...)}</div>
 *       <Button
 *         onClick={loadMoreArticles}
 *         showSpinner={isLoading}
 *         text="Load more articles"
 *       />
 *     </div>
 *   );
 * }
 */
export function usePagination<
	ItemT,
	ResponseT extends BasePaginatedResponse,
	ItemKeyT extends keyof ResponseT = keyof ResponseT,
>({
	defaultPageSize = 0,
	firstPageItemCountOffset = 0,
	initialComponent,
	initialItems = [],
	initialNextPageOffset,
	initialQueryVars = [],
	itemsKey,
	offsetQueryVarName,
	pageSizeQueryVarName,
	placeholderComponentName,
}: Params<ItemT, ResponseT, ItemKeyT>) {
	const router = useRouter();

	const [{ isActive, items, nextPageOffset, queryVars }, dispatch] = useReducer<
		Reducer<State<ItemT>, Action<ItemT>>
	>(reducer, {
		firstPageItemCountOffset,
		futureNextPageOffset: initialNextPageOffset,
		initialNextPageOffset,
		isActive: false,
		items: initialItems,
		itemsUpdateType: 'append',
		nextPageOffset: null,
		queryVars: new TupleSet<string, string>(
			// Ignore any mistakenly added offset key.
			...initialQueryVars.filter(([key]) => key !== offsetQueryVarName),
		),
	});

	const offsetParam = nextPageOffset
		? `${offsetQueryVarName}=${nextPageOffset}`
		: '';
	// Sort a new TupleSet to avoid mutation.
	const queryVarsParams =
		queryVars.size > 0 ? new TupleSet(queryVars).sort().toQueryString() : '';
	const queryVarsString = [offsetParam, queryVarsParams]
		.filter(Boolean)
		.join('&');

	const {
		component: placeholderComponent,
		isLoading,
		error,
	} = useLayoutServicePlaceholder<ResponseT>('jula-main', {
		componentName: placeholderComponentName,
		isActive,
		// Ignore any existing query vars.
		path: router.asPath.split('?')[0],
		queryVars: queryVarsString,
	});

	const component = placeholderComponent ?? initialComponent;
	// The offset should only be active when the removed products can be accessed
	// on a next page. Assume any page size set in the URL has been added through
	// pagination (could technically be set manually) and has thus already has
	// taken an offset into account (e.g. 77 rather than 80).
	const isFirstPageOffsetActive = Boolean(
		firstPageItemCountOffset &&
			!router.query[pageSizeQueryVarName] &&
			(items.length >= defaultPageSize || component?.hasNextPage),
	);

	const updateUrl = (pageSize: number) => {
		const baseUrl = globalThis.location?.href.split('?')[0];
		const params = [
			!pageSize || pageSize <= defaultPageSize
				? ''
				: `${pageSizeQueryVarName}=${pageSize}`,
			queryVarsParams,
		]
			.filter(Boolean)
			.join('&');
		// Params may be empty, avoid ending the URL with a question mark.
		const newUrl = [baseUrl, params].filter(Boolean).join('?');
		router
			.replace(newUrl, undefined, { scroll: false, shallow: true })
			.catch((replaceError) => {
				console.error(`Pagination updateUrl error: ${replaceError}`);
			});
	};

	// Set items when a new response is available.
	useEffect(() => {
		if (placeholderComponent) {
			dispatch({
				type: 'SET_ITEMS',
				items: is.array(placeholderComponent[itemsKey])
					? (placeholderComponent[itemsKey] as ItemT[])
					: ([] as ItemT[]),
				isFirstPageOffsetActive,
				futureNextPageOffset: placeholderComponent.hasNextPage
					? placeholderComponent.nextPageOffset
					: undefined,
			});
			updateUrl(placeholderComponent.nextPageOffset);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [placeholderComponent, itemsKey]);

	const loadMore = useCallback(() => {
		dispatch({ type: 'LOAD_NEXT_PAGE', isFirstPageOffsetActive });
	}, [isFirstPageOffsetActive]);

	const updateQueryVars = useCallback(
		(updater: (vars: typeof queryVars) => void) => {
			const newVars = new TupleSet(queryVars);
			updater(newVars);
			// Remove any mistakenly added offset key.
			newVars.delete(offsetQueryVarName);
			dispatch({ type: 'UPDATE_QUERY_VARS', queryVars: newVars });
		},
		[offsetQueryVarName, queryVars],
	);

	const getQueryVarValue = useCallback(
		(key: string) => queryVars.get(key),
		[queryVars],
	);

	const hasQueryVar = useCallback(
		(key: string, value?: string) => queryVars.has(key, value),
		[queryVars],
	);

	return {
		component,
		error,
		/** Get the value, if any, for the specified key. */
		getQueryVarValue,
		/** Check if the specified key or key-value pair is set. */
		hasQueryVar,
		isLoading,
		// Needed for the initial page load where SET_ITEMS hasn't been used yet.
		items: isFirstPageOffsetActive
			? items.slice(0, firstPageItemCountOffset)
			: items,
		/** Load more items for the current selection. */
		loadMore,
		/** Query var key-value pairs. */
		queryVarItems: queryVars.items as readonly [string, string][],
		/** Update filtering parameters to trigger a request for new data. */
		updateQueryVars,
	};
}
