// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconBankIdNo(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M5.456 6.286H1.091a1.144 1.144 0 0 1 0-2.286h4.365a1.144 1.144 0 0 1 0 2.286m0 9.143H1.091a1.145 1.145 0 0 1 0-2.287h4.365a1.144 1.144 0 0 1 0 2.285zm0 4.571H1.091a1.144 1.144 0 0 1 0-2.286h4.364a1.144 1.144 0 0 1 0 2.286zm8.727-9.143H9.819a1.144 1.144 0 0 1 0-2.285h4.364a1.144 1.144 0 0 1 0 2.285m0 4.573H9.819a1.144 1.144 0 0 1 0-2.286h4.364a1.144 1.144 0 0 1 0 2.285zm8.728-9.144h-4.365a1.144 1.144 0 0 1 0-2.286h4.364a1.144 1.144 0 0 1 0 2.286zm0 4.571h-4.365a1.144 1.144 0 0 1 0-2.285h4.364a1.144 1.144 0 0 1 0 2.285zm0 9.143h-4.365a1.144 1.144 0 0 1 0-2.286h4.364a1.144 1.144 0 0 1 0 2.286z" />
		</svg>
	);
}
IconBankIdNo.displayName = 'IconBankIdNo';
