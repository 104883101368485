import { useEffect, useState } from 'react';

import { useDebouncedCallback } from './useDebouncedCallback';

export interface WindowSize {
	height: number;
	width: number;
}

const getWindowSize = (): WindowSize => ({
	height: globalThis.innerHeight || 0,
	width: globalThis.innerWidth || 0,
});

export function useWindowSize(debounceTime = 150): WindowSize {
	const [size, setSize] = useState<WindowSize>(getWindowSize());

	const [handleResize] = useDebouncedCallback(() => {
		setSize(getWindowSize());
	}, debounceTime);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);

	return size;
}
