import { useEffect } from 'react';
import { useRouter } from 'next/router';

export function useRouteChange(
	eventType: 'routeChangeStart' | 'routeChangeComplete',
	callback: (url: string, shallow: boolean) => void,
) {
	const router = useRouter();
	useEffect(() => {
		router.events.on(eventType, callback);
		return () => {
			router.events.off(eventType, callback);
		};
	}, [callback, eventType, router]);
}
