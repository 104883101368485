import { useEffect, useState } from 'react';

import { type Breakpoint, breakpoints } from 'styles/media';
import { is } from 'utils/helpers';

/**
 * Check and listen to changes for a media query.
 *
 * NOTE! The initial value is `undefined` since the client's state is
 * unknown on the server.
 */
export function useMediaQuery(query: string) {
	const [matches, setMatches] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		const media = window.matchMedia(query);

		// Set the true initial value when on the client.
		setMatches(media.matches);

		// Watch for changes.
		const listener = (e: MediaQueryListEvent) => {
			setMatches(e.matches);
		};
		media.addEventListener('change', listener);
		return () => {
			media.removeEventListener('change', listener);
		};
	}, [query]);

	return matches;
}

export function useMinWidth(width: Breakpoint | number) {
	return useMediaQuery(
		is.number(width)
			? `(min-width: ${width}px)`
			: `(min-width: ${breakpoints[width]}px)`,
	);
}

export function useMaxWidth(width: Breakpoint | number) {
	return useMediaQuery(
		is.number(width)
			? `(max-width: ${width}px)`
			: `(max-width: ${breakpoints[width] - 1}px)`,
	);
}

export function usePrefersReducedMotion() {
	return useMediaQuery('(prefers-reduced-motion: reduce)');
}
