import { useCallback, useState } from 'react';

/**
 * Get a function that triggers a component render.
 */
export function useRerender() {
	const [, setNum] = useState(0);
	return useCallback(() => {
		setNum((num) => num + 1);
	}, []);
}
