// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconCloseOutline(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 .5C18.3.5 23.5 5.7 23.5 12S18.3 23.5 12 23.5.5 18.3.5 12 5.7.5 12 .5M12 2C6.5 2 1.99 6.5 1.99 12S6.51 22.02 12 22.02c5.5 0 10.01-4.52 10.01-10.01C22.01 6.5 17.5 1.99 12 1.99Z" />
			<path d="M16.83 7.18a.77.77 0 0 0-1.07 0L12 10.93 8.26 7.17a.77.77 0 0 0-1.07 1.07L10.93 12l-3.76 3.74a.77.77 0 0 0 .5 1.35c.22 0 .43-.1.57-.26L12 13.07l3.74 3.76c.15.2.37.3.62.3a.77.77 0 0 0 .46-1.37L13.07 12l3.76-3.76a.77.77 0 0 0 0-1.07z" />
		</svg>
	);
}
IconCloseOutline.displayName = 'IconCloseOutline';
