import { ListImageFormat } from 'models/asset';

import { BaseSearchResponse } from './search';
import { FacetResponse } from './shared';

interface ArchivedProductResponse {
	assets?: ListImageFormat[];
	brand?: { name?: string };
	id?: string;
	title?: string;
}

export const facetTypes = ['Brand', 'Category'] as const;
export type FacetType = (typeof facetTypes)[number];

// POST /api/v1/Search/productarchive
export interface ProductArchiveSearchResponse extends BaseSearchResponse {
	facets: FacetResponse<FacetType>[];
	products: ArchivedProductResponse[];
}
