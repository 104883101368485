import { useEffect } from 'react';
import type { EffectCallback } from 'react';

/** Run an effect only on the first render. */
export function useEffectOnce(effect: EffectCallback) {
	// The lint rule doesn't know what dependencies `effect` may have and warns
	// as a precaution.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(effect, []);
}
