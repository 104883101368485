import { useCallback } from 'react';
import { LayoutServiceData } from '@sitecore-jss/sitecore-jss-react';

import type { EngagementType } from 'types';

export function useEngagementGoals(layoutData: LayoutServiceData) {
	const sendEngagement = useCallback(
		async (props: EngagementType & { loggedIn: boolean }) => {
			const pageId = layoutData.sitecore?.route?.itemId;
			const url = '/api/engagement/';
			const body = {
				type: props.type,
				pageId,
				loggedIn: props.loggedIn,
				...('data' in props && props.data),
			};
			try {
				await fetch(url, {
					method: 'POST',
					body: JSON.stringify(body),
				});
			} catch (error) {
				console.log(error);
			}
		},
		[layoutData?.sitecore?.route?.itemId],
	);

	return { sendEngagement };
}
