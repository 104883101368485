import { assign, createMachine, spawn } from 'xstate';

import { toastItemMachine } from './toastItem.machine';

export type Variants = 'success' | 'information' | 'error';
interface ToastMachineContext {
	toasts: any[];
}
interface NotifyEvent {
	type: 'NOTIFY';
	link?: string;
	linkText?: string;
	message: string;
	variant?: Variants;
}
interface DoneEvent {
	type: 'TOAST.DONE';
	id: number;
}
type ToastMachineEvents = NotifyEvent | DoneEvent;
export interface ToastMachineGlobalEvents {
	toast: CustomEvent<{
		link?: string;
		linkText?: string;
		message: string;
		variant?: Variants;
	}>;
}

let toastCounter = 0;

export const toastMachine = createMachine(
	{
		id: 'ToastMachine',
		schema: {
			context: {} as ToastMachineContext,
			events: {} as ToastMachineEvents,
		},
		context: {
			toasts: [],
		},
		tsTypes: {} as import('./toast.machine.typegen').Typegen0,
		preserveActionOrder: true,
		initial: 'idle',
		states: {
			idle: {
				description:
					'Idle state, listening for custom events and creates toast actors',
				invoke: {
					src: 'listenForWindowEvent',
				},
				on: {
					'NOTIFY': {
						actions: ['addToastItem'],
					},
					'TOAST.DONE': { actions: ['removeToastItem'] },
				},
			},
		},
	},
	{
		actions: {
			addToastItem: assign({
				toasts: (context, event) => {
					const newToastName = `toast-${toastCounter}`;
					const newToast = spawn(
						toastItemMachine.withContext({
							id: newToastName,
							message: event.message,
							variant: event.variant || 'information',
							link: event.link,
							linkText: event.linkText,
						}),
						newToastName,
					);
					toastCounter++;
					return [...context.toasts, newToast];
				},
			}),
			removeToastItem: assign({
				toasts: (context, event) =>
					context.toasts.filter((t) => t.id !== event.id),
			}),
		},
		services: {
			listenForWindowEvent: () => (send) => {
				const toastNotifyListener = (event) => {
					const { link, linkText, message, variant } = event.detail;
					send({ type: 'NOTIFY', message, variant, link, linkText });
				};

				window.addEventListener('toast', toastNotifyListener, false);
				return () => {
					window.removeEventListener('toast', toastNotifyListener);
				};
			},
		},
	},
);
