// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconSpinner(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M20.7 9.67A9 9 0 1 1 9.67 3.31l.5 1.93a7 7 0 1 0 8.57 4.95l1.94-.52Z" />
		</svg>
	);
}
IconSpinner.displayName = 'IconSpinner';
