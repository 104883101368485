import type { GTMHelperInput } from './GTMHelperInputs';
import { formatGTMData } from './helpers';

export function pushToGTM(event: GTMHelperInput) {
	const gtmData = formatGTMData(event);
	if (!gtmData) {
		return;
	}

	if ('ecommerce' in gtmData) {
		window.dataLayer?.push({ ecommerce: null });
	}
	window.dataLayer?.push(gtmData);
}
