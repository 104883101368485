import { AuthMachineState } from './auth.machine';

export const selectIsAwaitingAuthAction = (state: AuthMachineState) =>
	state.hasTag('awaitingAuthAction');

export const selectIframeSrc = (state: AuthMachineState) =>
	state.context?.oauthParams?.iframeSrc;

export const selectIframeType = (state: AuthMachineState) =>
	state.context?.oauthParams?.iframeType;

export const selectErrorMessage = (state: AuthMachineState) =>
	state.context?.errorMessage;

export const selectUnregisteredToken = (state: AuthMachineState) =>
	state.context?.unregisteredToken;

export const selectIsLoadingGuiIframe = (state: AuthMachineState) =>
	state.hasTag('isLoadingGuiIframe');

export const selectIsLoadingGuiFull = (state: AuthMachineState) =>
	state.hasTag('isLoadingGuiFull') &&
	state.context?.oauthParams?.iframeType === 'gui_full';

export const selectIsWaitingForRefresh = (state: AuthMachineState) =>
	state.hasTag('delayedBrowserRefresh');
