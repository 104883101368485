/**
 * Placeholder
 */

import React from 'react';
import {
	ComponentRendering,
	Placeholder as SitecorePlaceholder,
	RouteData,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { PlaceholderComponentProps } from '@sitecore-jss/sitecore-jss-react/types/components/Placeholder';

import { hasProps } from 'utils/collection';

type Props<T extends Record<keyof T, unknown>> = Omit<
	PlaceholderComponentProps,
	'rendering'
> & {
	name:
		| 'breadcrumbs'
		| 'jula-account'
		| 'jula-empty'
		| 'jula-footer'
		| 'jula-header'
		| 'jula-inspiration'
		| 'jula-layout-landscape'
		| 'jula-layout-left'
		| 'jula-layout-right'
		| 'jula-layout-square'
		| 'jula-legal'
		| 'jula-main'
		| 'jula-side-navigation'
		| 'jula-size-guide'
		| 'jula-teaser'
		| 'jula-account-details'
		| 'jula-media-caption';
	partialProps?: T;
	rendering: ComponentRendering | RouteData | undefined;
};

/** A wrapper for Sitecore Placeholder with our ErrorMessage component provided */
export default function Placeholder<T extends Record<keyof T, unknown>>({
	...props
}: Props<T>) {
	if (!hasProps(props, 'rendering')) {
		return null;
	}

	return <SitecorePlaceholder {...props} />;
}
Placeholder.displayName = 'Placeholder';
