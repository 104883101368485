import { useEffect, useRef } from 'react';

import type { GlobalCustomEvent } from 'types';

export function useGlobalEvent<T extends keyof GlobalCustomEvent>(
	name: T,
	listener: (event: GlobalCustomEvent[T]) => void,
) {
	const savedListener = useRef<typeof listener>(listener);

	useEffect(() => {
		savedListener.current = listener;
	}, [listener]);

	useEffect(() => {
		const eventListener: EventListenerOrEventListenerObject = (e) => {
			savedListener.current(e as GlobalCustomEvent[T]);
		};

		window.addEventListener(name, eventListener);

		return () => {
			window.removeEventListener(name, eventListener);
		};
	}, [name]);
}
