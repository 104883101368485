import { useState } from 'react';

import { useInterval } from './useInterval';

/**
 * Use a global value that may not be available on initial load, like a
 * function from a third-party script.
 *
 * @example
 *
 * // window.myFunc is added by a third-party script with server issues that
 * // causes load times of 5+ seconds. The button will be disabled until the
 * // script has finished loading and the function is available.
 * function Component() {
 *   const myFunc = useLazyGlobalValue('myFunc');
 *   return <button disabled={!myFunc}>Run func</button>;
 * }
 */
export function useLazyGlobalValue<K extends keyof Window>(
	key: K,
): Window[K] | undefined {
	const [hasValue, setHasValue] = useState(
		Boolean(typeof window !== 'undefined' && window[key]),
	);

	useInterval(
		() => {
			if (window[key] !== undefined) {
				setHasValue(true);
			}
		},
		hasValue ? null : 1000,
	);

	return hasValue && typeof window !== 'undefined' ? window[key] : undefined;
}
