import type { Wishlist, WishlistVariant } from 'models/api';
import fetchData, { API_URL } from 'utils/fetchData';
import { createUrl } from 'utils/url';

import type { WishlistRequest } from './wishlist.types';

export function getVariantForId(wishList?: Wishlist, id?: string) {
	if (!wishList || !id) {
		return undefined;
	}
	const { categories } = wishList;
	let variant: WishlistVariant | undefined;
	for (const category of categories) {
		if (category.variants.some((item) => item.id === id)) {
			variant = category.variants.find((item) => item.id === id);
		}
	}

	return variant;
}

export function requestWishlistApi(request: WishlistRequest): any {
	let apiPath = '';
	let method = '';
	switch (request.requestType) {
		case 'fetchMiniWishlist':
			apiPath = `${API_URL}Wishlist/mini`;
			method = 'GET';
			break;
		case 'fetchWishlistById':
			apiPath = `${API_URL}Wishlist/${request.wishListId}`;
			method = 'GET';
			break;
		case 'fetchWishlist':
			apiPath = createUrl(`${API_URL}Wishlist`, request.urlParams);
			method = 'POST';
			break;
		case 'shareWishlist':
			apiPath = `${API_URL}Wishlist/share`;
			method = 'POST';
			break;
		case 'removeAll':
			apiPath = `${API_URL}Wishlist`;
			method = 'DELETE';
			break;
		case 'changeVariantQty':
			apiPath = createUrl(
				`${API_URL}Wishlist/variants/${request?.variantId}`,
				request.urlParams,
			);
			method = 'POST';
			break;
		case 'setVariantQty':
			apiPath = createUrl(`${API_URL}Wishlist/variants/${request?.variantId}`, {
				setDistinctValue: 'true',
				...request.urlParams,
			});
			method = 'POST';
			break;
		case 'removeVariant':
			apiPath = `${API_URL}Wishlist/variants/${request?.variantId}`;
			method = 'DELETE';
			break;
		case 'addOneToWishList':
			apiPath = createUrl(
				`${API_URL}Wishlist/mini/variants/${request?.variantId}`,
				{ qty: 1, onlySellable: 'false', ...request.urlParams },
			);
			method = 'POST';
			break;
		default:
			break;
	}

	return new Promise<void>((resolve, reject) => {
		fetchData(apiPath, {
			method,
		})
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
}
