// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconHelp(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 2C6.51 2 2 6.51 2 12s4.51 10 10 10 10-4.51 10-10S17.49 2 12 2m1 17h-2v-2h2zm2.07-7.75-.9.92A3.5 3.5 0 0 0 13 15h-2v-.5a4.06 4.06 0 0 1 1.17-2.83l1.24-1.26a2 2 0 0 0-.84-3.33 2.03 2.03 0 0 0-2.47 1.27.88.88 0 0 1-.82.65h-.3a.87.87 0 0 1-.82-1.12 4.01 4.01 0 0 1 3.23-2.83 4.07 4.07 0 0 1 3.87 1.8c1.06 1.3.98 3.2-.19 4.4" />
		</svg>
	);
}
IconHelp.displayName = 'IconHelp';
