import { useEffect, useMemo, useRef } from 'react';

import { debounce } from 'utils/helpers';

/**
 * Run an function after a debounce delay.
 *
 * @example
 *
 * function Component() {
 *   const [
 *     handleThingDebounced,
 *     flushHandleThing,
 *     cancelHandleThing,
 *   ] = useDebouncedCallback(
 *     () => {
 *       // Do something
 *     },
 *     200,
 *   );
 *   useEffect(() => {
 *     // Some value changed and the queued, debounced call is no longer
 *     // relevant or any previous calls should be cleared.
 *     // flushHandleThing();
 *     // cancelHandleThing();
 *   }, [someValue])
 *
 *   return <OtherComponent value={someValue} onThing={handleThingDebounced} />;
 * }
 */
export function useDebouncedCallback<T extends (...args: any[]) => any>(
	callback: T,
	delay: number,
	dependencies: Array<any> = [],
) {
	// Callback from the current render.
	const savedCallback = useRef<T>();
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	const debounced = useMemo(
		() =>
			debounce((...args: Parameters<T>) => {
				savedCallback.current?.(...args);
			}, delay),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[delay, ...dependencies],
	);

	// On unmount and if the debounce changes
	useEffect(
		() => () => {
			debounced.flush();
		},
		[debounced],
	);

	return [debounced, debounced.flush, debounced.cancel] as const;
}
