/**
 * LinkOrButton
 */

import React, {
	type AnchorHTMLAttributes,
	type ButtonHTMLAttributes,
	type ReactNode,
} from 'react';

import Link from 'components/Link';
import type { HTMLAttributes } from 'types';

type AnchorAttrs = AnchorHTMLAttributes<HTMLAnchorElement>;
type ButtonAttrs = ButtonHTMLAttributes<HTMLButtonElement>;

interface Props extends HTMLAttributes<HTMLAnchorElement | HTMLButtonElement> {
	/** Link/button text */
	children: ReactNode;

	/** Link URL, results in an anchor instead of a button */
	href: string | undefined;

	/** If the button is disabled, or without href for links */
	disabled?: boolean;

	/** Link rel if `href` is set */
	rel?: AnchorAttrs['rel'];

	/** Link target if `href` is set */
	target?: AnchorAttrs['target'];

	/** Button type if `href` is NOT set */
	type?: ButtonAttrs['type'];
}

// TODO: consider a custom `disabled` state that is only visual to avoid focus
// loss, e.g. on a 'load more' button that's disabled while loading.
// https://adrianroselli.com/2024/02/dont-disable-form-controls.html

/** Base container for a link or button, depending on if `href` is set. */
const LinkOrButton = React.forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	Props
>(
	(
		{
			children,
			disabled,
			href,
			onClick,
			rel,
			target,
			type = 'button',
			...attrs
		},
		ref,
	) => {
		if (href) {
			if (disabled) {
				return (
					// https://www.scottohara.me/blog/2021/05/28/disabled-links.html
					<a
						{...attrs}
						// @ts-ignore
						ref={ref}
						role="link"
						aria-disabled="true"
					>
						{children}
					</a>
				);
			}
			return (
				<Link
					{...attrs}
					// @ts-ignore
					ref={ref}
					href={href}
					rel={rel}
					target={target}
					onClick={onClick}
				>
					{children}
				</Link>
			);
		}

		return (
			<button
				{...attrs}
				// @ts-ignore
				ref={ref}
				disabled={disabled}
				onClick={disabled ? undefined : onClick}
				// Lint rule doesn't understand that the type only allows valid values.
				// eslint-disable-next-line react/button-has-type
				type={type}
			>
				{children}
			</button>
		);
	},
);
LinkOrButton.displayName = 'LinkOrButton';

export default LinkOrButton;
