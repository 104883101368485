/**
 * BrowserWarning
 */

import React from 'react';
import { useI18n } from 'utils/i18n';

const OLD_BROWSER_MESSAGE_ID = 'old-browser-warning';

/**
 * Test for optional chaining support, which sets the cutoff to Safari 13.
 *
 * Ensure the test is correctly formatted by running it as is (without the
 * string quotes) in a browser console.
 *
 * - https://kangax.github.io/compat-table/es2016plus/
 * - https://caniuse.com/ciu/comparison
 */
const BROWSER_TEST = '({a:1}?.a)';

/**
 * Script that runs the browser test code and inserts an error message if the
 * test fails.
 *
 * Testing is done by constructing a new function that contains the browser test
 * snippet (slightly less bad than running `eval` directly). A SyntaxError will
 * be thrown if the browser doesn't understand the generated function's body.
 * Deliberately skipping modern syntax like arrow function and template literal
 * in the script to make the test work in outdated browsers.
 */
const BROWSER_TEST_SCRIPT = `(function(){try{Function('${BROWSER_TEST}')}catch(_){var d=document.getElementById('${OLD_BROWSER_MESSAGE_ID}');d.innerHTML='<div class="'+d.dataset.classes+'">'+d.dataset.text+'</div>'}})();`;

const WARNING_CLASSES =
	'bg-errorLighter px-4 py-2 text-center text-sm text-error';

/** Displays a warning if JavaScript is disabled of if the browser is too old. */
export default function BrowserWarning() {
	// We're explicitly working outside of React here since the whole point is
	// that the main code bundle may not work.
	/* eslint-disable react/no-danger */

	const { t } = useI18n();

	return (
		<>
			<noscript>
				<div className={WARNING_CLASSES}>
					{t('browser_javascript_disabled_warning_text')}
				</div>
			</noscript>
			<div
				id={OLD_BROWSER_MESSAGE_ID}
				data-text={t('browser_too_old_warning_text')}
				data-classes={WARNING_CLASSES}
				// Dangerous + suppress to have React ignore the DOM manipulation
				// done by the script.
				// https://github.com/facebook/react/issues/10923#issuecomment-338715787
				dangerouslySetInnerHTML={{ __html: '' }}
				suppressHydrationWarning
			/>
			<script dangerouslySetInnerHTML={{ __html: BROWSER_TEST_SCRIPT }} />
		</>
	);
}
BrowserWarning.displayName = 'BrowserWarning';
