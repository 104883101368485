import { ProductResponse } from 'models/api/variant';
import type { Asset, AssetFormat, ListImageFormat } from 'models/asset';
import type { Product } from 'models/product';
import type { ProductCard, RelatedProductCard } from 'models/productCard';
import { arrayUniqueBy, filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';

export interface Thumbnail {
	alt: string;
	id: number;
	src: string;
}

export interface WarningSymbol {
	id: string;
	src: string;
}

// TODO: remove? getProductCardImage should cover most cases
/**
 * Singles out the provided ratio from the images array.
 */
export function getProductImageFormat<I extends AssetFormat | ListImageFormat>(
	type: I['type'],
	images: I[] | undefined,
): I | undefined {
	return images?.find((img) => img.type === type);
}

// TODO: Rename when getProductImageFormat is removed?
/**
 * Returns the image for the productCard.
 */
export function getProductCardImage(
	productOrImages:
		| ProductCard
		| RelatedProductCard
		| ListImageFormat[]
		| AssetFormat[],
	placeholderFallback: boolean = false,
): string | undefined {
	const assets = is.array(productOrImages)
		? productOrImages
		: productOrImages.assets;
	const format = assets?.find(
		(img) => img.type === 'Templated square transparent',
	);
	const fallback = placeholderFallback
		? '/assets/images/placeholder-image.png'
		: undefined;
	if (!format) {
		return fallback;
	}
	if ('url' in format) {
		return format.url.location || fallback;
	}
	return format.location || fallback;
}

/**
 * Returns the main image for the product.
 */
export function getMainImageDetail(
	product: Product | ProductResponse | undefined,
): Thumbnail | undefined {
	if (!product?.assets) {
		return undefined;
	}

	const productImage = product.assets.find(
		(asset) => asset.type === 'ProductImage',
	);
	const mainImageVersion = productImage?.versions?.find(
		(version) => version.subType === 'MainProductImage',
	);
	const mainImageFormat = mainImageVersion?.formats?.find(
		(format) => format.type === 'Templated square transparent',
	);

	if (mainImageFormat) {
		return {
			alt: product.title || '',
			src: mainImageFormat.url?.location || '',
			id: 1,
		};
	}

	return undefined;
}

/**
 * Returns a sorted array of all product images.
 */
export function getAllImages(
	product: Product | ProductResponse,
): Thumbnail[] | undefined {
	const productImage = product?.assets?.find(
		(asset) => asset.type === 'ProductImage',
	);
	if (!productImage?.versions?.length) {
		return undefined;
	}

	return productImage.versions
		.map((version, i) => {
			const format = version.formats?.find(
				({ type }) => type === 'Templated square transparent',
			);

			return {
				alt: '',
				src: format?.url?.location || '',
				id: i + 1,
			};
		})
		.sort((x, y) => (x.id > y.id ? 1 : -1));
}

/**
 * Returns the brand logo image for the product.
 */
export function getBrandLogoImage(
	product: Product | ProductResponse,
): string | undefined {
	if (!product.brand?.brandLogo?.length) {
		return undefined;
	}

	const logotype = product.brand.brandLogo.find(
		(asset) => asset.type === 'Logotype',
	);
	const primaryLogoVersion = logotype?.versions?.find(
		(version) => version.subType === 'PrimaryLogo',
	);
	const mainImageFormat = primaryLogoVersion?.formats?.find(
		(format) => format.type === 'ODL 80px height transparent',
	);

	return mainImageFormat?.url?.location;
}

export function getShortcutBrandLogoImage(
	brandImages: Asset[] | undefined | null,
): string | undefined {
	if (!brandImages?.length) {
		return undefined;
	}

	const logotype = brandImages.find((asset) => asset.type === 'Logotype');
	const primaryLogoVersion = logotype?.versions?.find(
		(version) => version.subType === 'PrimaryLogo',
	);
	let mainImageFormat = primaryLogoVersion?.formats?.find(
		(format) => format.type === 'ODL 300px height transparent',
	);
	if (!mainImageFormat) {
		mainImageFormat = primaryLogoVersion?.formats?.find(
			(format) => format.type === 'ODL 80px height transparent',
		);
	}

	return mainImageFormat?.url?.location || undefined;
}

export function getProductInformationSymbols(product: Product): string[] {
	const urls: string[] = [];

	if (!product.assets) {
		return urls;
	}

	const symbol = product.assets.find((asset) => asset.type === 'Symbol');
	const versions = symbol?.versions?.filter(
		(version) => version.subType === 'ProductInformationSVGSymbol',
	);
	if (versions) {
		versions.forEach((version) => {
			if (!version.formats?.length) {
				return;
			}
			const format = version.formats.find(
				({ type, url }) => type === 'SVG' && url?.location,
			);
			if (format?.url.location) {
				urls.push(format.url.location);
			}
		});
	}

	return urls;
}

export function getProductWarningImages(
	product: Product,
): WarningSymbol[] | undefined {
	// Some products have duplicate symbols, remove them.
	return arrayUniqueBy(
		filterTruthy(
			product.chemicalInformation?.hazardPictogram?.map(({ asset, id }) => ({
				id,
				src: asset?.versions?.[0]?.formats?.[0]?.url?.location,
			})),
			'src',
		),
		'src',
	);
}

export function getVerticalEnergyLabel(product: Product | ProductResponse) {
	return product.energyInformation?.label?.versions
		?.find((collection) => collection.subType === 'VerticalEnergyLabel')
		?.formats?.find((format) => format.type === 'ODL 360px width transparent');
}
