import { assign, createMachine, StateFrom } from 'xstate';

import { is } from 'utils/helpers';

import { fetchStock } from './stock.services';
import {
	StockMachineContext,
	StockMachineEvents,
	StockMachineServices,
} from './stock.types';

export const stockMachine = createMachine(
	{
		tsTypes: {} as import('./stock.machine.typegen').Typegen0,
		schema: {
			context: {} as StockMachineContext,
			events: {} as StockMachineEvents,
			services: {} as StockMachineServices,
		},
		id: 'stockMachine',
		initial: 'idle',
		context: {
			failureRetries: 0,
			currentProductId: '',
		},
		states: {
			idle: {
				id: 'idle',
			},
			fetchStockForAllStores: {
				tags: 'loadingStock',
				initial: 'fetchStock',
				exit: 'resetFailureRetries',
				states: {
					fetchStock: {
						invoke: {
							id: 'fetchAllStoresStock',
							src: 'fetchAllStoresStock',
							onDone: {
								target: '#idle',
								actions: ['setAllStoresStock', 'setCurrentProductStock'],
							},
							onError: {
								target: 'failure',
							},
						},
					},
					failure: {
						entry: 'increaseFailureRetries',
						after: {
							RETRY_DELAY: { target: 'fetchStock', cond: 'shouldRetryRequest' },
						},
					},
				},
			},
			fetchStoreStock: {
				tags: 'loadingStock',
				initial: 'fetchStock',
				exit: 'resetFailureRetries',
				states: {
					fetchStock: {
						invoke: {
							id: 'fetchStoreStock',
							src: 'fetchStoreStock',
							onDone: {
								target: '#idle',
								actions: ['setCurrentStoreStock', 'setCurrentProductStock'],
							},
							onError: {
								target: 'failure',
							},
						},
					},
					failure: {
						entry: 'increaseFailureRetries',
						after: {
							RETRY_DELAY: { target: 'fetchStock', cond: 'shouldRetryRequest' },
						},
					},
				},
			},
			fetchNearbyStores: {
				tags: 'loadingNearbyStoresStock',
				initial: 'fetchStock',
				exit: 'resetFailureRetries',
				states: {
					fetchStock: {
						invoke: {
							id: 'fetchNearbyStoresStock',
							src: 'fetchNearbyStoresStock',
							onDone: {
								target: '#idle',
								actions: 'setNearbyStoresStock',
							},
							onError: {
								target: 'failure',
							},
						},
					},
					failure: {
						entry: 'increaseFailureRetries',
						after: {
							RETRY_DELAY: { target: 'fetchStock', cond: 'shouldRetryRequest' },
						},
					},
				},
			},
			fetchStockForVariants: {
				tags: 'loadingVariantsStock',
				initial: 'fetchStock',
				exit: 'resetFailureRetries',
				states: {
					fetchStock: {
						invoke: {
							id: 'fetchStockForVariants',
							src: 'fetchStockForVariants',
							onDone: {
								target: '#idle',
								actions: 'setVariantsStock',
							},
							onError: {
								target: 'failure',
							},
						},
					},
					failure: {
						entry: 'increaseFailureRetries',
						after: {
							RETRY_DELAY: { target: 'fetchStock', cond: 'shouldRetryRequest' },
						},
					},
				},
			},
		},
		on: {
			FETCH_STOCK_FOR_STORE: [
				{
					cond: 'storeLoadedInAllStores',
					actions: ['setStoreId', 'setCurrentStoreStockFromAllStores'],
					target: 'fetchStoreStock',
				},
				{
					actions: 'setStoreId',
					target: 'fetchStoreStock',
				},
			],
			FETCH_STOCK_FOR_ALL_STORES: {
				target: 'fetchStockForAllStores',
				cond: 'noStoresLoaded',
			},
			FETCH_STOCK_FOR_VARIANTS: {
				target: 'fetchStockForVariants',
				actions: 'setVariantIds',
			},
			FETCH_STOCK_FOR_NEARBY_STORES: {
				target: 'fetchNearbyStores',
			},
		},
	},
	{
		guards: {
			shouldRetryRequest: (context) => context.failureRetries < 5,
			noStoresLoaded: (context) => !is.arrayWithLength(context.allStoresStock),
			storeLoadedInAllStores: (context, event) =>
				is.arrayWithLength(context.allStoresStock) &&
				context.allStoresStock.some(
					(store) => store.store?.id === event.storeId,
				),
		},
		actions: {
			increaseFailureRetries: assign({
				failureRetries: (context) => context.failureRetries + 1,
			}),
			resetFailureRetries: assign({
				failureRetries: (context) => 0,
			}),
			setVariantsStock: assign({
				variantsStock: (_context, event) => event.data,
				variantIds: (_context) => [],
			}),
			setCurrentStoreStock: assign({
				currentStoreStock: (context, event) =>
					event.data
						?.find((variant) => variant.id === context.currentProductId)
						?.storeStock?.stocks?.find(
							(store) => store.store?.id === context.storeId,
						),
			}),
			setCurrentStoreStockFromAllStores: assign({
				currentStoreStock: (context) =>
					context.allStoresStock?.find(
						(store) => store.store?.id === context.storeId,
					),
			}),
			setAllStoresStock: assign({
				allStoresStock: (context, event) =>
					event.data?.find((variant) => variant.id === context.currentProductId)
						?.storeStock?.stocks,
			}),
			setNearbyStoresStock: assign({
				nearbyStoresStock: (context, event) =>
					event.data?.find((variant) => variant.id === context.currentProductId)
						?.storeStock?.stocks,
			}),
			setCurrentProductStock: assign({
				currentProductStock: (context, event) =>
					event.data?.find(
						(variant) => variant.id === context.currentProductId,
					),
			}),
			setVariantIds: assign({
				variantIds: (context, event) => [...event.variantIds],
			}),
			setStoreId: assign({
				storeId: (context, event) => event.storeId,
			}),
		},
		services: {
			fetchAllStoresStock: (context, event) =>
				fetchStock(context.currentProductId),
			fetchStoreStock: (context) =>
				fetchStock(context.currentProductId, context.storeId),
			fetchNearbyStoresStock: (context, event) => {
				const nearbyStoreIds = context?.currentStoreStock?.store.nearbyStores
					?.map((nearbyStore) => nearbyStore.store.id)
					.filter(is.truthy);
				return fetchStock(context.currentProductId, nearbyStoreIds);
			},
			fetchStockForVariants: (context, event) =>
				fetchStock(context.variantIds, context.storeId),
		},
		delays: {
			RETRY_DELAY: (context) => (context.failureRetries || 1) * 2000,
		},
	},
);
export type StockMachineState = StateFrom<typeof stockMachine>;
