// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconVisibilityOff(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 7a5 5 0 0 1 5 5 4.9 4.9 0 0 1-.36 1.83l2.92 2.92A11.8 11.8 0 0 0 22.99 12a11.83 11.83 0 0 0-11-7.5 11.7 11.7 0 0 0-3.98.7l2.16 2.16A4.9 4.9 0 0 1 12 7M2 4.27l2.28 2.28.46.46A11.8 11.8 0 0 0 1 12a11.82 11.82 0 0 0 15.38 6.66l.42.42L19.73 22 21 20.73 3.27 3zM7.53 9.8l1.55 1.55A2.8 2.8 0 0 0 9 12a3 3 0 0 0 3 3 2.8 2.8 0 0 0 .65-.08l1.55 1.55A4.967 4.967 0 0 1 7.53 9.8m4.31-.78 3.15 3.15.02-.16a3 3 0 0 0-3-3z" />
		</svg>
	);
}
IconVisibilityOff.displayName = 'IconVisibilityOff';
