import { publicRuntimeConfig } from 'config';
import { Cart, PaymentRequest } from 'models/api';
import { createUrl } from 'utils/url';

export function getPaymentRequest(cart: Cart): PaymentRequest {
	let screen = { colorDepth: 0, height: 0, width: 0 };
	if (typeof window !== 'undefined') {
		screen = window.screen;
	}

	return {
		// Don't use href since it may contain interfering query variables
		returnUrl: createUrl(`${window.location.origin}/checkout/`, {
			cartId: cart.id,
			verifyRedirectPayment: 'true',
		}),
		paymentMethod: {
			type: cart.selectedPayment?.paymentMethodIdProvider,
		},
		storePaymentMethod: false,
		// Add browser info. Should only be required for a few payment methods
		// but the API wants it for every one.
		// https://docs.adyen.com/checkout/3d-secure/api-reference#browserinfo
		browserInfo: {
			acceptHeader: '*/*',
			colorDepth: screen.colorDepth,
			javaEnabled: true,
			javaScriptEnabled: true,
			language: publicRuntimeConfig?.NEXT_PUBLIC_DEFAULT_LANGUAGE,
			screenHeight: screen.height,
			screenWidth: screen.width,
			timeZoneOffset: String(new Date().getTimezoneOffset()),
			// Can be spoofed, could possibly be removed as well.
			userAgent: navigator.userAgent || 'Unknown',
		},
	};
}
