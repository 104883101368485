import { useCallback, useEffect, useRef } from 'react';

/**
 * Run an effect after a delay.
 *
 * @example
 *
 * function Component() {
 *   // Can also just run useTimeout like a useEffect, without clear/restart.
 *   const [clearTimeout, restartTimeout] = useTimeout(() => {
 *     // Run effect after a second
 *   }, 1000);
 *
 *   return null;
 * }
 */
export function useTimeout(
	effect: () => void,
	delay: number | null,
): [() => void, () => void] {
	const timeout = useRef<ReturnType<typeof setTimeout>>();

	// Keep the effect callback from the current render in a ref. The effect
	// can't be used raw as a dependency since that will restart the timer
	// every render.
	const savedEffect = useRef<typeof effect>(effect);
	useEffect(() => {
		savedEffect.current = effect;
	}, [effect]);

	const clear = useCallback(() => {
		if (timeout.current) {
			clearTimeout(timeout.current);
			timeout.current = undefined;
		}
	}, []);

	const restart = useCallback(() => {
		clear();

		if (delay !== null) {
			timeout.current = setTimeout(() => {
				savedEffect.current();
			}, delay);
		}
	}, [delay, clear]);

	// Set on mount, clear on unmount.
	useEffect(() => {
		restart();
		return clear;
	}, [delay, restart, clear]);

	return [clear, restart] as const;
}
