/**
 * Round a number, optionally to X decimals.
 *
 * @example
 *
 * round(1.2)
 * // => 1
 *
 * round(1.7)
 * // => 2
 *
 * round(1.006, 2)
 * // => 1.01
 */
export function round(num: number, precision: number = 0) {
	const p = 10 ** precision;
	return Math.round(num * p * (1 + Number.EPSILON)) / p;
}

/**
 * Get the weighted average for the supplied numbers.
 *
 * @example
 *
 * getWeightedAverage(3, 9, 19)
 * // => 2.516
 */
export function getWeightedAverage(...counts: number[]) {
	const totalCounts = counts.reduce((acc, count) => acc + count, 0);
	const weightedSum = counts.reduce(
		(acc, count, index) => acc + (index + 1) * count,
		0,
	);
	return round(weightedSum / totalCounts, 3);
}

/**
 * Get the weighted percentage for the supplied numbers.
 *
 * @example
 *
 * getWeightedPercentage(3, 9, 19)
 * // => 75.8
 */
export function getWeightedPercentage(...counts: number[]) {
	const totalCount = counts.reduce((acc, count) => acc + count, 0);
	const weightedSum = counts.reduce(
		(acc, count, index) => acc + (index + 1) * count,
		0,
	);
	const weightedAverage = weightedSum / totalCount;
	const percentage = ((weightedAverage - 1) / (counts.length - 1)) * 100;
	return round(percentage, 1);
}

function degreesToRadians(angleDegrees: number) {
	return (angleDegrees * Math.PI) / 180;
}

/** Earth's radius at the Equator in meters. */
const EARTH_RADIUS = 6_378_137;

export interface Coordinate {
	lat: number;
	long: number;
}

/**
 * Get the distance in meters between two coordinates, using the [haversine
 * formula](https://en.wikipedia.org/wiki/Haversine_formula).
 *
 * Based on https://github.com/dcousens/haversine-distance.
 */
export function getDistanceBetween(from: Coordinate, to: Coordinate) {
	const fromLatRad = degreesToRadians(from.lat);
	const toLatRad = degreesToRadians(to.lat);
	const fromLongRad = degreesToRadians(from.long);
	const toLongRad = degreesToRadians(to.long);
	const hav = (x: number) => Math.sin(x / 2) ** 2;
	const ht =
		hav(toLatRad - fromLatRad) +
		Math.cos(fromLatRad) * Math.cos(toLatRad) * hav(toLongRad - fromLongRad);
	return round(2 * EARTH_RADIUS * Math.asin(Math.sqrt(ht)));
}
