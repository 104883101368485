// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconVerifiedUser(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m9.83 16.78-.7-.7-2.84-2.84a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l2.83 2.83 6.36-6.37a1 1 0 0 1 1.42 0 1 1 0 0 1 0 1.42l-6.37 6.36-.7.7a1 1 0 0 1-.71.3 1 1 0 0 1-.71-.3z" />
			<path d="M12 24a11.92 11.92 0 0 1-8.49-3.52A11.92 11.92 0 0 1 0 12a11.92 11.92 0 0 1 3.52-8.48A11.92 11.92 0 0 1 12 0a11.92 11.92 0 0 1 8.48 3.52A11.92 11.92 0 0 1 24 12a11.92 11.92 0 0 1-3.52 8.49A11.92 11.92 0 0 1 12 24m0-22A10.01 10.01 0 0 0 2 12a10.01 10.01 0 0 0 10 10 10.01 10.01 0 0 0 10-10A10.01 10.01 0 0 0 12 2" />
		</svg>
	);
}
IconVerifiedUser.displayName = 'IconVerifiedUser';
