import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { useEffectOnce } from 'hooks';

export type StoreIdName = { id: string; name: string };
export const StoreContext = createContext<{
	selectedStore: StoreIdName | undefined;
	isLoading: boolean;
	setSelectedStore: React.Dispatch<
		React.SetStateAction<StoreIdName | undefined>
	>;
}>({ selectedStore: undefined, isLoading: false, setSelectedStore: () => {} });

interface Props {
	children: React.ReactNode;
}
export function SelectedStoreContextProvider({ children }: Props) {
	const [selectedStore, setSelectedStore] = useState<StoreIdName | undefined>();
	const [isLoading, setIsLoading] = useState(true);
	useEffectOnce(() => {
		const initialStoreJsonString = window.localStorage.getItem('julaStoreV2');
		if (initialStoreJsonString) {
			setSelectedStore(JSON.parse(initialStoreJsonString));
			setIsLoading(false);
		} else {
			// we set selected store to an empty object to be able to tell that it's done
			// loading the store from localstorage
			setIsLoading(false);
		}
	});
	useEffect(() => {
		if (window && selectedStore !== undefined) {
			window.localStorage.setItem('julaStoreV2', JSON.stringify(selectedStore));
		}
	}, [selectedStore]);
	const value = useMemo(
		() => ({
			selectedStore,
			isLoading,
			setSelectedStore,
		}),
		[isLoading, selectedStore],
	);

	return (
		<StoreContext.Provider value={value}>{children}</StoreContext.Provider>
	);
}
SelectedStoreContextProvider.displayName = 'SelectedStoreContextProvider';
export function useSelectedStore() {
	return useContext(StoreContext);
}
