export default class Mutex {
	private mutex = Promise.resolve();

	lock(): PromiseLike<() => void> {
		let begin: (unlock: () => void) => void = (unlock) => {};

		this.mutex = this.mutex.then(() => new Promise(begin));

		return new Promise((res) => {
			begin = res;
		});
	}

	async dispatch<T>(fn: (() => T) | (() => PromiseLike<T>)): Promise<T> {
		const unlock = await this.lock();
		try {
			return await Promise.resolve(fn());
		} finally {
			unlock();
		}
	}
}
