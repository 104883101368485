import { useState } from 'react';
import { waitFor } from 'xstate/lib/waitFor';

import { useGlobalStateContext } from 'contexts';
import { is } from 'utils/helpers';

type ShareWishlist = {
	title?: string;
};

export function useShareWishlist({
	title = '',
}: ShareWishlist): [boolean, () => void] {
	const [copiedToClipboard, setCopiedToClipboard] = useState(false);

	const { wishlistService } = useGlobalStateContext();
	const { send } = wishlistService;

	const share = async () => {
		send({ type: 'SHARE_WISHLIST' });
		const wishlistState = await waitFor(wishlistService, (state) =>
			state.hasTag('wishlistWasShared'),
		);

		const wishlistShareId = wishlistState?.context.wishlistShareId;
		if (wishlistShareId) {
			const url = `${window.location.href}${wishlistShareId}/`;
			if (is.func(navigator.share)) {
				navigator
					.share({
						title,
						url,
					})
					.catch((error) => console.log(error));
			} else {
				navigator.clipboard.writeText(url);
				setCopiedToClipboard(true);
				setTimeout(() => {
					setCopiedToClipboard(false);
				}, 3000);
			}
		}
	};

	return [copiedToClipboard, share] as const;
}
