// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconCartAdd(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m20.79 14.24.4-2.02c-.99.51-2.08.78-3.19.78a7.04 7.04 0 0 1-6.92-8H4.82l-.19-2.09a1 1 0 0 0-1-.91h-2a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1.09l.91 10a5.77 5.77 0 0 0 1.65 4.16A2.97 2.97 0 0 0 4.64 20a3 3 0 0 0 3 3c1.64 0 3-1.35 3-3 0-.34-.07-.68-.19-1h4.37a3.01 3.01 0 0 0 2.82 4c1.64 0 3-1.35 3-3s-1.36-3-3-3h-10a1.7 1.7 0 0 1-1.58-1.09l13.8-.86a1 1 0 0 0 .93-.8Z" />
			<path d="M18 0c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6m3 7h-2v2a1 1 0 0 1-1 1 1 1 0 0 1-1-1V7h-2a1 1 0 0 1-1-1 1 1 0 0 1 1-1h2V3a1 1 0 0 1 1-1 1 1 0 0 1 1 1v2h2a1 1 0 0 1 1 1 1 1 0 0 1-1 1" />
		</svg>
	);
}
IconCartAdd.displayName = 'IconCartAdd';
