import Router from 'next/router';
import {
	parse as parseUrlQuery,
	stringify as stringifyUrlQuery,
} from 'query-string';
import {
	type ActorRefFrom,
	assign,
	createMachine,
	sendParent,
	type StateFrom,
} from 'xstate';
import { pure } from 'xstate/lib/actions';

import { UpdateCartEvent } from 'state-machines/checkout';
import { browserRedirect } from 'utils/helpers';

import {
	isPaymentReadyForCheckout,
	isPaymentTypeWithComponentState,
} from '../helpers';

import { finalizePayment, requestPayment } from './payment.services';
import type {
	PaymentMachineContext,
	PaymentMachineEvents,
	PaymentResult,
} from './payment.types';

export const paymentMachine = createMachine(
	{
		preserveActionOrder: true,
		id: 'payment',
		initial: 'idle',
		schema: {
			context: {} as PaymentMachineContext,
			events: {} as PaymentMachineEvents,
			services: {} as {
				requestPayment: {
					data: PaymentResult;
				};
			},
		},
		tsTypes: {} as import('./payment.machine.typegen').Typegen0,
		context: {
			paymentErrorTypes: ['PaymentMethodNotNeeded'],
			verifyPaymentPayload: null,
			paymentResult: {
				orderNo: '',
				resultCode: '',
			},
			paymentComponentState: {},
			redirectResult: '',
			redirectId: '',
		},
		states: {
			idle: {
				on: {
					CART_UPDATED: [
						{
							target: 'idle',
							actions: 'updateData',
						},
					],
					SELECT_PAYMENT_METHOD: {
						target: 'loading',
						actions: [
							'resetPaymentComponentState',
							'saveData',
							'updateDataOptimistic',
						],
					},
					SET_PAYMENT_COMPONENT_STATE: {
						target: 'idle',
						actions: [
							'setPaymentComponentState',
							'sendPaymentComponentStateToParent',
						],
					},
				},
			},
			loading: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
				},
			},
			paymentInitiating: {
				invoke: {
					id: 'requestPayment',
					src: 'requestPayment',
					onDone: [
						{
							target: 'idle',
							cond: 'requiresAdditionalAction',
							actions: ['setResult', 'checkCartCheckoutStatus'],
						},
						{
							target: 'waitingForVerifyPaymentEvent',
							cond: 'paymentPending',
							actions: ['setResult'],
						},
						{
							target: 'waitingForVerifyPaymentEvent',
							cond: 'redirectShopper',
							actions: ['redirectToPaymentProvider', 'addCartIdToUrl'],
						},
						{
							target: 'idle',
							actions: ['checkCartCheckoutStatus'],
						},
					],
					onError: {
						target: 'idle',
						actions: ['notifyParentPaymentFailed'],
					},
				},
			},
			waitingForVerifyPaymentEvent: {
				on: {
					VERIFY_REDIRECT_PAYMENT: {
						target: 'idle',
						actions: ['setPaymentPayload', 'verifyPayment'],
					},
				},
			},
			paymentFinalizing: {
				invoke: {
					id: 'finalizePayment',
					src: 'finalizePayment',
					onDone: {
						target: 'idle',
						actions: 'notifyParentPaymentDone',
					},
					onError: {
						target: 'error',
					},
				},
			},
			error: {},
		},
		on: {
			FINALIZE_PAYMENT: {
				target: 'paymentFinalizing',
			},
			CHECK_PAYMENT_STATE: [
				{
					target: 'idle',
					cond: 'isValidPaymentState',
					actions: 'sendPaymentOk',
				},
				{
					target: 'idle',
					actions: 'sendPaymentError',
				},
			],
			INIT_PAYMENT: {
				target: 'paymentInitiating',
			},
			CART_UPDATED: {
				actions: 'updateData',
			},
			RESET_TO_OLD: {
				target: 'idle',
				actions: 'resetToOldPayment',
			},
		},
	},
	{
		guards: {
			isValidPaymentState: (context) =>
				isPaymentReadyForCheckout(
					context.selectedPayment?.selectedPaymentType,
					context.paymentComponentState,
				),
			requiresAdditionalAction: (_context, event) =>
				Boolean(
					event.data.resultCode === 'Pending' &&
						event.data.action &&
						event.data.action.type === 'await',
				),
			paymentPending: (_context, event) => event.data.resultCode === 'Pending',
			redirectShopper: (_context, event) =>
				event.data.resultCode === 'RedirectShopper',
		},
		actions: {
			updateData: assign({
				cart: (_context, event) => event.value!,
				availablePaymentMethods: (context, event) =>
					event?.value?.availablePaymentMethods ||
					context?.availablePaymentMethods,
				selectedPayment: (context, event) => event?.value?.selectedPayment,
				errors: (_context, event) => event?.value?.errorList,
			}),

			resetToOldPayment: assign({
				selectedPayment: (context) => context.oldSelectedPayment,
			}),

			notifyParentPaymentDone: sendParent((_context, event) => ({
				type: 'PAYMENT_DONE',
				orderData: event.data,
			})),

			notifyParentPaymentFailed: sendParent((_context) => ({
				type: 'PAYMENT_FAILED',
			})),
			sendPaymentError: pure((context) => {
				let error = '';
				if (
					context.selectedPayment &&
					isPaymentTypeWithComponentState(
						context.selectedPayment.selectedPaymentType,
					) &&
					!context.paymentComponentState?.isValid
				) {
					const paymentKeys = {
						scheme: 'CardPaymentInfoError',
						blik: 'BlikPaymentInfoError',
						general: 'GeneralPaymentInfoError',
					};
					error =
						paymentKeys[String(context.selectedPayment.selectedPaymentType)] ||
						paymentKeys.general;
				}
				return sendParent({
					type: 'PAYMENT_STATE_ERROR',
					error,
				});
			}),
			sendPaymentOk: pure((_context, _event) =>
				sendParent({
					type: 'PAYMENT_STATE_OK',
				}),
			),

			setPaymentComponentState: assign({
				paymentComponentState: (_context, event) => event.state,
			}),
			resetPaymentComponentState: assign({
				paymentComponentState: (_context) => ({ isValid: false }),
			}),

			addCartIdToUrl: (context, _event) => {
				if (!context.cart) {
					return;
				}
				const currentPath = window.location.pathname;
				const currentQuery = window.location.search;
				const urlQuery =
					currentQuery === undefined ? {} : parseUrlQuery(currentQuery);
				urlQuery.cartId = context.cart.id;

				Router.replace(
					`${currentPath}?${stringifyUrlQuery(urlQuery)}`,
					undefined,
					{ shallow: true },
				);
			},

			setResult: assign({
				paymentResult: (_context, event) => event.data,
			}),

			checkCartCheckoutStatus: sendParent((_context) => ({
				type: 'CHECK_CHECKOUT_STATUS',
			})),

			verifyPayment: sendParent((context) => ({
				type: 'VERIFY_REDIRECT_PAYMENT',
				payload: context.verifyPaymentPayload,
			})),

			updateDataOptimistic: assign({
				selectedPayment: (_context, event) => event?.value,
				oldSelectedPayment: (context) => context?.selectedPayment,
			}),

			setPaymentPayload: assign({
				verifyPaymentPayload: (_context, event) =>
					event.paymentVerificationPayload,
			}),

			sendPaymentComponentStateToParent: sendParent((context, _event) => ({
				type: 'SET_PAYMENT_COMPONENT_STATE',
				state: context.paymentComponentState,
			})),

			saveData: sendParent((context, event) => {
				const updateCartEvent: UpdateCartEvent = {
					type: 'UPDATE_CART',
					selectedPayment: event.value,
				};
				return updateCartEvent;
			}),
			redirectToPaymentProvider: (_context, event) => {
				const { action } = event.data;
				if (action?.url) {
					// @ts-expect-error TODO: skip data? It doesn't exist in the TS type
					// which is from the Adyen package. Keep it for now in case it's
					// actually there in practice and removing it would break payment.
					const data = action.data as object | undefined;
					browserRedirect(action.url, action.method, data);
				}
			},
		},
		services: {
			requestPayment: (context, _event) => requestPayment(context),
			finalizePayment: (context, _event) =>
				finalizePayment(
					context.cart?.id === undefined ? context.redirectId : context.cart.id,
				),
		},
	},
);
export type PaymentMachineState = StateFrom<typeof paymentMachine>;
export type PaymentMachineActor = ActorRefFrom<typeof paymentMachine>;
