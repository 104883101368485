// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFile(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M6 2a2 2 0 0 0-1.99 2L4 20a2 2 0 0 0 1.99 2H18a2 2 0 0 0 2-2V8l-6-6zm7 7V3.5L18.5 9z" />
		</svg>
	);
}
IconFile.displayName = 'IconFile';
