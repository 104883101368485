import React, { createContext, useContext, useEffect, useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import { Store } from 'models/store';
import fetchData, { API_URL } from 'utils/fetchData';

import { useSelectedStore } from './SelectedStoreContextProvider';

export const StoreInfoContext = createContext<{
	storeInfo: Store | undefined;
	isLoading: boolean;
	error: any;
}>({
	storeInfo: undefined,
	isLoading: false,
	error: undefined,
});

interface Props {
	children: React.ReactNode;
}
export function SelectedStoreInfoContextProvider({ children }: Props) {
	const { selectedStore, setSelectedStore } = useSelectedStore();
	const {
		data: storeInfo,
		error,
		isLoading,
	} = useSWRImmutable(
		selectedStore?.id ? `${API_URL}Stores/${selectedStore.id}` : null,
		fetchData<Store>,
	);

	useEffect(() => {
		if (error && error.status === 400) {
			setSelectedStore(undefined);
		}
	}, [error]);

	const value = useMemo(
		() => ({
			storeInfo,
			isLoading,
			error,
		}),
		[storeInfo, isLoading, error],
	);

	return (
		<StoreInfoContext.Provider value={value}>
			{children}
		</StoreInfoContext.Provider>
	);
}
export function useSelectedStoreInfo() {
	return useContext(StoreInfoContext);
}
SelectedStoreInfoContextProvider.displayName = 'StoreInfoContextProvider';
