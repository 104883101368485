import { useEffect } from 'react';

import { is } from 'utils/helpers';

// TODO: Check uses. Was previously useOutsideClick so it's probably a good idea
// to switch to the new, actual outsideClick hook in several places.
/**
 * Trigger a callback when clicking on a page-overlay element.
 */
export function usePageOverlayClick(callback: (e: MouseEvent) => void) {
	const handleClick = (e: MouseEvent) => {
		if (
			is.instance(e.target, HTMLElement) &&
			e.target.classList.contains('page-overlay')
		) {
			callback(e);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	});
}
