// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconShoppinglist(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M9 6h6a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-1.277a1.984 1.984 0 0 0-3.446 0H9a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1" />
			<path d="M18 3h-1v2a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V3H6a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-2 15H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2m0-6H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2" />
		</svg>
	);
}
IconShoppinglist.displayName = 'IconShoppinglist';
