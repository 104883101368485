import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const getHash = (url: string | undefined) => url?.split('#')[1] || '';

/**
 * Get the current URL hash, excluding the # sign. Always empty on the server.
 */
export function useHash(): string {
	const router = useRouter();
	// The router's asPath will not contain the hash.
	// https://github.com/vercel/next.js/issues/25202
	const [hash, setHash] = useState(getHash(globalThis.location?.href));

	useEffect(() => {
		const handleHashChange = (url: string) => {
			setHash(getHash(url));
		};
		// Check on page load.
		handleHashChange(globalThis.location.href);

		router.events.on('hashChangeComplete', handleHashChange);

		return () => {
			router.events.off('hashChangeComplete', handleHashChange);
		};
	}, [router]);

	return hash;
}
