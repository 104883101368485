// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconThumbUp(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M1 21h4V9H1zm22-11a2.006 2.006 0 0 0-2-2h-6.31l.95-4.57.03-.32a1.5 1.5 0 0 0-.44-1.06L14.17 1 7.59 7.59A1.96 1.96 0 0 0 7 9v10a2.006 2.006 0 0 0 2 2h9a1.99 1.99 0 0 0 1.84-1.22l3.02-7.05A2 2 0 0 0 23 12z" />
		</svg>
	);
}
IconThumbUp.displayName = 'IconThumbUp';
