// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconExpand(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m16.606 8.293-4.95 4.95-4.95-4.95a1 1 0 0 0-1.414 0 1 1 0 0 0 0 1.414l4.95 4.95.707.707a1 1 0 0 0 1.414 0l.707-.707 4.95-4.95a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0" />
		</svg>
	);
}
IconExpand.displayName = 'IconExpand';
