// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconJulaJ(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path
				d="M18.763 4.402a1.17 1.17 0 0 1 .759 1.782c-.14.377-.446.409-.771.471a1.3 1.3 0 0 0-.548.207 2.87 2.87 0 0 0-.492 1.512c-.176 1.178-.22 2.814-.764 6.087-.666 4-4.641 5.522-7.148 5.522a10 10 0 0 1-1.269 0C6.695 19.914 4 18.743 4 16.513a2.93 2.93 0 0 1 2.918-2.928 2.7 2.7 0 0 1 .755.052A2 2 0 0 1 9.44 15.59c-.071.781-.662 1-.467 1.373a.83.83 0 0 0 .806.362c.865-.162 1.371-1.22 1.886-4.365.176-1.064.414-3.658.414-4.049a7.4 7.4 0 0 0 .034-1.88c-.206-.172-.813-.105-1.085-.147a.77.77 0 0 1-.582-.684 2.7 2.7 0 0 1 .038-.687c.025-.941 1.232-1.452 3.829-1.507l2.618.006a5.1 5.1 0 0 1 1.83.392z"
				fillRule="evenodd"
			/>
		</svg>
	);
}
IconJulaJ.displayName = 'IconJulaJ';
