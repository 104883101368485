// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFacebook(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M15.813 3.985h2.191V.169A28 28 0 0 0 14.813 0C11.65 0 9.486 1.987 9.486 5.639V9H6v4.266h3.486V24h4.274V13.267h3.345L17.636 9h-3.877V6.062c0-1.233.333-2.077 2.051-2.077z" />
		</svg>
	);
}
IconFacebook.displayName = 'IconFacebook';
