/**
 * Skiplink
 */

import React, {
	type AnchorHTMLAttributes,
	type MouseEventHandler,
	type ReactNode,
} from 'react';

import { isFocusable, scrollTo } from 'utils/dom';

interface Props extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
	/** Element ID to target */
	anchor: string;

	/** Link text */
	children: ReactNode;

	/** Link class names */
	className?: string;

	/** Throw an error if the anchor target is missing? */
	strict?: boolean;
}

/** Link for scrolling to anchors on the same page. */
const ScrollLink = React.forwardRef<HTMLAnchorElement, Props>(
	({ anchor, children, className, onClick, strict, ...props }, ref) => {
		const target = anchor.replaceAll('#', '');

		const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
			e.preventDefault();
			onClick?.(e);
			// eslint-disable-next-line unicorn/prefer-query-selector
			const el = document.getElementById(target);
			if (!el) {
				if (strict) {
					throw new Error(`Missing link target element with ID '${target}'`);
				}
				return;
			}
			if (!isFocusable(el)) {
				el.tabIndex = -1;
			}
			scrollTo(el);
			el.focus();
		};

		// No need for next Link here since the event is always prevented.
		return (
			<a
				{...props}
				ref={ref}
				href={`#${target}`}
				onClick={handleClick}
				className={className}
			>
				{children}
			</a>
		);
	},
);
ScrollLink.displayName = 'ScrollLink';

export default ScrollLink;
