// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFilterActive(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M15 7V6a1 1 0 0 1 2 0v1h2a1 1 0 0 1 0 2h-2v1a1 1 0 0 1-2 0V9H5a1 1 0 0 1 0-2zm-8 8v-1a1 1 0 0 1 2 0v1h10a1 1 0 0 1 0 2H9v1a1 1 0 0 1-2 0v-1H5a1 1 0 0 1 0-2z" />
		</svg>
	);
}
IconFilterActive.displayName = 'IconFilterActive';
