import { ItemStockResponse } from 'models/api';

import { StockMachineState } from './stock.machine';

export const selectHasWebStock = (current: StockMachineState) => {
	if (current?.context?.result === undefined) {
		return true;
	}
	return current?.context?.result?.[0]?.webStock?.inStock;
};
export const selectVariantsStock = (current: StockMachineState) =>
	current.context.variantsStock;
export const selectCurrentProductStock = (
	current: StockMachineState,
): ItemStockResponse | undefined => current.context.currentProductStock;

export const selectCurrentStoreStock = (current: StockMachineState) =>
	current.context.currentStoreStock;
export const selectAllStoresStock = (current: StockMachineState) =>
	current.context.allStoresStock;
export const selectNearbyStoresStock = (current: StockMachineState) =>
	current.context.nearbyStoresStock;

export const selectIsLoadingInitialStock = (current: StockMachineState) =>
	current.hasTag('loadingStock') && !current.context.currentProductStock;
export const selectIsLoadingStock = (current: StockMachineState) =>
	current.hasTag('loadingStock');
export const selectIsLoadingVariantsStock = (current: StockMachineState) =>
	current.hasTag('loadingVariantsStock');
export const selectIsLoadingNearbyStoresStock = (current: StockMachineState) =>
	current.hasTag('loadingNearbyStoresStock');
