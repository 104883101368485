import type { NextApiRequest, NextApiResponse } from 'next';

import { ConnectErrorDescription, type GuiType } from 'models/connect/oauth';
import { createUrl } from 'utils/url';

export const AUTH_CODE_SUCCESS = 'AUTH_CODE_SUCCESS';
export const AUTH_CODE_FAILURE = 'AUTH_CODE_FAILURE';
interface ExtendedNextApiRequest extends NextApiRequest {
	body: {
		code?: string;
		error?: string;
		error_description?: ConnectErrorDescription;
		state?: string;
	};
}
export const OIDC_REDIRECT_URL_AUTH_CODE_QUERY_PARAM = 'code';
export const OIDC_REDIRECT_URL_ERROR_DESCRIPTION_QUERY_PARAM =
	'errorDescription';
export const OIDC_REDIRECT_URL_CUSTOMER_TYPE_QUERY_PARAM = 'customerType';
export const OIDC_REDIRECT_URL_RETURN_PATH_QUERY_PARAM = 'returnPath';

type OIDCRedirectUrlQueryParamKeys =
	| typeof OIDC_REDIRECT_URL_AUTH_CODE_QUERY_PARAM
	| typeof OIDC_REDIRECT_URL_ERROR_DESCRIPTION_QUERY_PARAM
	| typeof OIDC_REDIRECT_URL_CUSTOMER_TYPE_QUERY_PARAM
	| typeof OIDC_REDIRECT_URL_RETURN_PATH_QUERY_PARAM;

type OIDCRedirectUrlQueryParams = Partial<
	Record<OIDCRedirectUrlQueryParamKeys, string>
>;
export interface State {
	accountReturnPath?: string;
	customerType: string;
	guiType: GuiType;
	redirectPath: string;
}

export default (req: ExtendedNextApiRequest, res: NextApiResponse) => {
	res.setHeader(
		'Cache-Control',
		'private, no-cache, no-store, max-age=0, must-revalidate',
	);
	const { body } = req;
	const state = body.state
		? (JSON.parse(Buffer.from(body.state, 'base64').toString()) as State)
		: undefined;
	const redirectPath = state?.redirectPath || '/';
	const guiType = state?.guiType;
	const returnPath = state?.accountReturnPath;
	const customerType = state?.customerType || '';
	if (guiType === 'gui_full') {
		const params: OIDCRedirectUrlQueryParams = {};
		params.customerType = customerType;
		if (body.code) {
			params.code = body.code;
			params.returnPath = returnPath;

			res.redirect(302, createUrl(redirectPath, params));
		} else if (body.error_description) {
			if (body.error_description === 'x-UserCancel') {
				res.redirect(302, redirectPath);
			} else {
				params.errorDescription = body.error_description;
				res.redirect(302, createUrl(redirectPath, params));
			}
		} else {
			params.errorDescription = 'unknown';
			res.redirect(302, createUrl(redirectPath, params));
		}
	} else {
		const target = 'parent';
		if (body.code) {
			res.status(200).send(
				`<script>window.${target}.postMessage(${JSON.stringify({
					type: AUTH_CODE_SUCCESS,
					code: body.code,
				})}, window.location.origin);</script>`,
			);
		} else {
			res.status(200).send(
				`<script>window.${target}.postMessage(${JSON.stringify({
					type: AUTH_CODE_FAILURE,
					error: body.error,
					errorDescription: body.error_description,
				})}, window.location.origin);</script>`,
			);
		}
	}
};
