import { createContext, useContext } from 'react';

import type { FeatureToggles } from 'lib/page-props';

export const FeatureToggleContext = createContext<any>({});

interface Props {
	children: React.ReactNode;
	featureToggles: FeatureToggles;
}
export function FeatureToggleContextProvider({
	children,
	featureToggles,
}: Props) {
	return (
		<FeatureToggleContext.Provider value={featureToggles}>
			{children}
		</FeatureToggleContext.Provider>
	);
}
FeatureToggleContextProvider.displayName = 'FeatureToggleContextProvider';

export function useFeatureToggle() {
	return useContext<FeatureToggles>(FeatureToggleContext);
}
