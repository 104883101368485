import React from 'react';
import Script from 'next/script';

import IconButton from 'components/IconButton';
import { publicRuntimeConfig } from 'config';
import { useChat } from 'hooks';
import { sendGlobalEvent } from 'utils/helpers';

export default function ZendeskChat() {
	const { messageCount, messengerIsOpen, openChatOrPopover } = useChat();
	const zendeskKey =
		publicRuntimeConfig?.NEXT_PUBLIC_CUSTOMER_SERVICE_ZENDESK_CHAT_ID;

	if (!zendeskKey) {
		return null;
	}

	return (
		<>
			<Script
				id="ze-snippet"
				strategy="lazyOnload"
				onLoad={() => {
					if (typeof window !== 'undefined' && window.zE) {
						window.zE('messenger:on', 'close', () => {
							sendGlobalEvent('set-show-zendesk-chat-button', false);
						});
						window.zE('messenger:on', 'open', () => {
							sendGlobalEvent('set-show-zendesk-chat-button', true);
						});
						window.zE('messenger:on', 'unreadMessages', (count: number) => {
							sendGlobalEvent('set-zendesk-chat-message-count', count);
						});
					}
				}}
				src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`}
			/>
			{!messengerIsOpen && (
				<IconButton
					icon="chat"
					iconColor="white"
					hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarken"
					text="chatta"
					badgeProps={{
						color: 'black',
						className: 'absolute -top-1 -right-0.5',
					}}
					badge={messageCount > 0 ? messageCount : undefined}
					className="fixed bottom-4 right-4 z-chatButton border-2 border-white bg-julaRed shadow-lg print:hidden"
					onClick={openChatOrPopover}
				/>
			)}
		</>
	);
}
ZendeskChat.displayName = 'ZendeskChat';
