import { useCallback, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => any;

/**
 * Create a function that can only be invoked N times. Subsequent calls return
 * the value of the last invocation.
 */
export function useLimitedCallback<T extends Callback>(
	func: T,
	callCountLimit: number = 1,
): T {
	const callCount = useRef<number>(0);
	const result = useRef<ReturnType<T>>();

	return useCallback<T>(
		// @ts-expect-error Generic woes, TODO: look into.
		(...args: Parameters<T>): ReturnType<T> => {
			if (callCount.current < callCountLimit) {
				result.current = func(...args);
				callCount.current += 1;
			}
			return result.current!;
		},
		[callCountLimit, func],
	);
}

/**
 * Create a function that can only be invoked once. Repeat calls return the
 * value of the first invocation.
 *
 * @example
 *
 * function Component() {
 *   const sendSomeEventOnce = useCallbackOnce(sendSomeEvent);
 *
 *   // Event will only be sent on the first open.
 *   useEffect(() => {
 *     if (isOpen) {
 *       sendSomeEventOnce();
 *     }
 *   }, [isOpen, items]);
 *
 *   return null;
 * }
 */
export function useCallbackOnce<T extends Callback>(func: T): T {
	return useLimitedCallback(func, 1);
}
