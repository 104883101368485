import type { ActorRefFrom, StateFrom } from 'xstate';
import { assign, createMachine } from 'xstate';

interface MenuMachineContext {
	isOpen: boolean;
}

interface OpenMenuEvent {
	type: 'OPEN_MENU';
}
interface CloseMenuEvent {
	type: 'CLOSE_MENU';
}

type MenuMachineEvents = OpenMenuEvent | CloseMenuEvent;

export type MenuMachineState = StateFrom<typeof menuMachine>;
export type MenuMachineActor = ActorRefFrom<typeof menuMachine>;

export const selectIsOpening = (state: MenuMachineState) =>
	state.matches('opening');

export const selectIsClosing = (state: MenuMachineState) =>
	state.matches('closing');

export const selectIsOpen = (state: MenuMachineState) => state.context.isOpen;

export const menuMachine = createMachine<MenuMachineContext, MenuMachineEvents>(
	{
		id: 'menu',
		initial: 'idle',
		context: {
			isOpen: false,
		},
		states: {
			idle: {
				on: {
					OPEN_MENU: { target: 'opening' },
					CLOSE_MENU: { target: 'closing' },
				},
			},
			opening: {
				entry: [assign((_context) => ({ isOpen: true }))],
				always: 'idle',
			},
			closing: {
				entry: [assign((_context) => ({ isOpen: false }))],
				always: 'idle',
			},
		},
	},
);
