import type { CartError } from 'models/api';

import { CartMachineState } from './cart.machine';
import { isAnonUserWithRequiredDetails } from './helpers';

export const selectCartQuantity = (state: CartMachineState) =>
	state.context.totalQty;

export const selectVariants = (state: CartMachineState) =>
	state.context.cart?.variants;

export const selectCheckoutStatus = (state: CartMachineState) =>
	state.context.cart?.checkoutStatus;

export const selectBonusDiscounts = (state: CartMachineState) =>
	state.context.cart?.bonusDiscounts;

export const selectGiftCards = (state: CartMachineState) =>
	state.context.cart?.giftCards;

export const selectCampaignDiscount = (state: CartMachineState) =>
	state.context.cart?.campaignDiscount;

export const selectErrorList = (
	state: CartMachineState,
): CartError[] | undefined => state.context.cart?.errorList;

export const selectPurchaseButtonState = (state: CartMachineState) =>
	state.context.purchaseButtonState;

export const selectFreightDiscount = (state: CartMachineState) =>
	state.context.cart?.freightDiscount;

export const selectAdditionalSalesProducts = (state: CartMachineState) =>
	state.context.additionalSalesProducts;

export const selectAdditionalSalesToastText = (state: CartMachineState) =>
	state.context.additionalSalesToastText;

export const selectCartId = (state: CartMachineState) => state.context.id;

export const selectAdditionalSalesIsOpen = (state: CartMachineState) =>
	state.context.additionalSalesIsOpen;

export const selectIsLoadingCart = (state: CartMachineState) =>
	state.hasTag('loadingCart');

export const selectIsInitialLoading = (state: CartMachineState) =>
	state.hasTag('loadingCart') && !state.context.cart;

export const selectAnonUserIsReadyForCheckout = (
	state: CartMachineState,
): boolean => isAnonUserWithRequiredDetails(state.context.cart);

export const selectSummaries = (state: CartMachineState) =>
	state.context.cart?.summaries;

export const selectSavingTotalSumSummary = (state: CartMachineState) =>
	state.context.cart?.savingSummaries.find(
		(summary) => summary.sumType === 'SavingTotalSum',
	);
