// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconExpandCollapse(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m16.606 15.363-4.95-4.95-4.95 4.95a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414L10.242 9l.707-.707a1 1 0 0 1 1.414 0L13.07 9l4.95 4.95a1 1 0 0 1 0 1.414 1 1 0 0 1-1.414-.001" />
		</svg>
	);
}
IconExpandCollapse.displayName = 'IconExpandCollapse';
