import { usePagedData } from 'hooks/data-fetching/internal';
import {
	FacetType,
	facetTypes,
	ProductArchiveSearchResponse,
} from 'models/api/productArchive';
import fetchData, { API_URL } from 'utils/fetchData';

export function useProductArchiveSearch(
	params: {
		categoryId?: string;
		searchString: string;
		sortOption: string;
	} & Partial<Record<FacetType, string>>,
	isActive: boolean,
) {
	return usePagedData<ProductArchiveSearchResponse, 'products'>({
		baseUrl: `${API_URL}search/productarchive`,
		itemsKey: 'products',
		isActive,
		pageSize: 10,
		fetcher: (key) => {
			// the key here is used to have a sane way to get params that need to be in the body
			// into the fetcher, therefore we need to rebuild the request url
			const url = new URL(key);
			const mappedFacets = facetTypes.map((facet) => {
				const facetValue = url.searchParams.get(facet);
				url.searchParams.delete(facet);
				return (
					facetValue && {
						facetType: facet,
						selected: [facetValue],
					}
				);
			});
			const body = JSON.stringify(mappedFacets.filter(Boolean));

			return fetchData<ProductArchiveSearchResponse>(url.toString(), {
				method: 'POST',
				body,
			});
		},
		queryParams: params,
		pageOffsetQueryKey: 'offset',
		pageSizeQueryKey: 'page-size',
	});
}
