import React from 'react';
import Head from 'next/head';

interface Props {
	iconPath: string;
}

export default function Favicons({ iconPath }: Props) {
	return (
		<Head>
			<link
				rel="manifest"
				href="/site.webmanifest"
				crossOrigin="use-credentials"
			/>
			<link rel="icon" href={`${iconPath}/favicon.ico`} />
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href={`${iconPath}/apple-touch-icon.png`}
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href={`${iconPath}/favicon-32x32.png`}
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href={`${iconPath}/favicon-16x16.png`}
			/>
			<link
				rel="mask-icon"
				href={`${iconPath}/safari-pinned-tab.svg`}
				color="#cb333b"
			/>
			<meta name="msapplication-TileColor" content="#cb333b" />
			<meta name="theme-color" content="#ffffff" />
		</Head>
	);
}
Favicons.displayName = 'Favicons';
