// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconStarHalf(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 17.27V2L9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
		</svg>
	);
}
IconStarHalf.displayName = 'IconStarHalf';
