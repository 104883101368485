// TODO: this is a duplicate of the functtion in formatCartForRequest.ts
// it should be replaced by that other one
export const getDiscountCodesDuplicate = (
	cart: any,
	discountKeys = [
		'freightDiscount',
		'bonusDiscounts',
		'campaignDiscount',
		'genericDiscounts',
	],
) => {
	const codes: string[] = [];
	for (const key in cart) {
		if (discountKeys.includes(key) && cart[key] !== null) {
			if (key === 'bonusDiscounts') {
				const bonusDiscounts = cart[key];
				bonusDiscounts.forEach((bonusDiscount: { checkNumber: string }) =>
					codes.push(bonusDiscount.checkNumber),
				);
			} else {
				const code = cart[key].checkNumber;
				if (code) {
					codes.push(code);
				}
			}
		}
	}
	return codes;
};
