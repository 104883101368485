/**
 * LoadingSpinner
 */

import React from 'react';
import clsx from 'clsx';

type Variant = 'regular' | 'dashing';
type CircleSize = 'small' | 'medium' | 'large';
type CircleStrokeWidth = 'thin' | 'medium' | 'thick';
type TrackColor = 'julaRed' | 'grey' | 'transparent';
type SpinnerColor = 'julaRed' | 'grey' | 'dark';

interface Props {
	variant?: Variant;
	size?: CircleSize;
	trackColor?: TrackColor;
	spinnerColor?: SpinnerColor;
	circleStrokeWith?: CircleStrokeWidth;
	className?: string;
}

const STROKE_WIDTHS: Record<CircleStrokeWidth, string> = {
	thin: '1',
	medium: '3',
	thick: '6',
};

export default function LoadingSpinner({
	variant,
	size = 'large',
	trackColor = 'grey',
	spinnerColor = 'dark',
	circleStrokeWith = 'medium',
	className,
}: Props) {
	return (
		<svg
			viewBox="25 25 50 50"
			className={clsx(
				'animate-spinnerRotate',
				className,
				size === 'small' && 'h-16 w-16',
				size === 'medium' && 'h-30 w-30',
				size === 'large' && 'h-40 w-40',
			)}
		>
			<circle
				cx="50"
				cy="50"
				fill="none"
				r="20"
				strokeWidth={STROKE_WIDTHS[circleStrokeWith]}
				stroke="currentColor"
				strokeLinecap="round"
				className={clsx(
					trackColor === 'julaRed' && 'text-julaRed',
					trackColor === 'grey' && 'text-grey',
					trackColor === 'transparent' && 'text-transparent',
				)}
			/>
			<circle
				cx="50"
				cy="50"
				r="20"
				fill="none"
				strokeWidth={STROKE_WIDTHS[circleStrokeWith]}
				strokeMiterlimit="10"
				strokeDasharray={variant === 'dashing' ? '1px, 200px' : '40px, 200px'}
				strokeDashoffset="0px"
				stroke="currentColor"
				strokeLinecap={variant === 'dashing' ? 'round' : 'square'}
				className={clsx(
					variant === 'dashing' && 'animate-spinnerDash',
					spinnerColor === 'julaRed' && 'text-julaRed',
					spinnerColor === 'grey' && 'text-grey',
					spinnerColor === 'dark' && 'text-greyDarker',
				)}
			/>
		</svg>
	);
}

LoadingSpinner.displayName = 'LoadingSpinner';
