/**
 * OptionalLink
 */

import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import type { HTMLTagName } from 'types';

import Link, { type Props as LinkProps } from './Link';

interface Props extends Omit<LinkProps, 'href'> {
	/** Link text. */
	children: ReactNode;

	/** Classes for both link and fallback. */
	className?: string;

	/** Classes for only the fallback. */
	fallbackClassName?: string;

	/** Tag to use when href isn't set. */
	fallbackTag?: HTMLTagName;

	/** Link URL. */
	href: LinkProps['href'] | undefined;

	/** Classes for only the link. */
	linkClassName?: string;
}

/** Render a link if `href` prop is truthy, otherwise a non-link tag. */
export default function OptionalLink({
	children,
	className,
	fallbackClassName,
	fallbackTag: Fallback = 'div',
	href,
	linkClassName,
	...props
}: Props) {
	return href ? (
		<Link href={href} className={clsx(className, linkClassName)} {...props}>
			{children}
		</Link>
	) : (
		<Fallback className={clsx(className, fallbackClassName)}>
			{children}
		</Fallback>
	);
}
OptionalLink.displayName = 'OptionalLink';
