import { getPaymentRequest } from 'utils/business-logic';
import fetchData, { API_URL } from 'utils/fetchData';

import { isPaymentTypeWithComponentState } from '../helpers';

import type { PaymentMachineContext, PaymentResult } from './payment.types';

/**
 * Make request to start the payment flow.
 *
 * @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__payment
 */
export function requestPayment(
	context: PaymentMachineContext,
): Promise<PaymentResult> {
	const { cart } = context;
	if (!cart) {
		return Promise.reject();
	}
	const selectedPaymentType = cart.selectedPayment?.selectedPaymentType;

	const paymentRequest = getPaymentRequest(cart);
	const componentState = context.paymentComponentState;
	if (
		isPaymentTypeWithComponentState(selectedPaymentType) &&
		componentState?.isValid
	) {
		paymentRequest.paymentMethod = componentState.data?.paymentMethod;
	}
	return new Promise<PaymentResult>((resolve, reject) => {
		fetchData(`${API_URL}Cart/${cart.id}/payment`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(paymentRequest),
		})
			.then((data: any) => resolve(data))
			.catch(() => reject());
	});
}

/**
 * Make request to finalize the payment (after payment is done).
 *
 * @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__finalize
 */
export function finalizePayment(cartId: string): Promise<any> {
	return new Promise((resolve, reject) => {
		fetchData(`${API_URL}Cart/${cartId}/finalize`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		})
			.then((data: any) => resolve(data))
			.catch(() => reject());
	});
}
