import { useEffect, useRef } from 'react';

/**
 * Run an effect at a timed interval. Can be paused by setting a `null` delay.
 *
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @example
 *
 * function Component() {
 *   useInterval(() => {
 *     // Run effect every second
 *   }, 1000);
 *
 *   return null;
 * }
 */
export function useInterval(effect: () => void, delay: number | null) {
	const savedEffect = useRef<typeof effect>(effect);

	// Callback from the current render.
	useEffect(() => {
		savedEffect.current = effect;
	}, [effect]);

	useEffect(() => {
		function tick() {
			savedEffect.current();
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => {
				clearInterval(id);
			};
		}
		return undefined;
	}, [delay]);
}
