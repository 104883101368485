export function requestSessionEndpoint(): any {
	return new Promise(async (resolve, reject) => {
		const response = await fetch('/api/signin/session/');
		if (response.ok) {
			if (response.status === 204) {
				resolve({ statusCode: response.status });
				return;
			}
			const data = await response.json();
			resolve({
				statusCode: response.status,
				tokenData: {
					tokenExpiry: data.tokenExpiry,
				},
			});
			return;
		}
		reject();
	});
}

export function requestTokenData(): any {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch('/api/token/introspection/');
			if (response.ok) {
				if (response.status === 204) {
					resolve({ statusCode: response.status });
					return;
				}
				const data = await response.json();
				resolve({
					statusCode: response.status,
					tokenData: { ...data },
				});
				return;
			}
			reject();
		} catch (error) {
			console.error('/api/token/introspection error', error);
			reject();
		}
	});
}
