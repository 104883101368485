// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconPlus(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<rect width="12" height="2" rx="1" transform="translate(6 11)" />
			<rect width="2" height="12" rx="1" transform="translate(11 6)" />
		</svg>
	);
}
IconPlus.displayName = 'IconPlus';
