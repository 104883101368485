import { Variant, WishlistVariant } from 'models/api';
import { Product } from 'models/product';
import { ProductCard, RelatedProductCard } from 'models/productCard';

export const checkSupercheap = (
	product:
		| ProductCard
		| RelatedProductCard
		| Product
		| Variant
		| WishlistVariant
		| undefined,
) => {
	if (!product) {
		return false;
	}
	if ('salesAttributes' in product) {
		return Boolean(product.salesAttributes?.isHero);
	}
	if ('isHero' in product) {
		return product.isHero;
	}
	return false;
};
