import { EditorMargin } from 'lib/component-props';
import { is } from 'utils/helpers';

const MARGINS: Record<EditorMargin, string> = {
	None: '',
	Half: 'mt-4 md:mt-6',
	Single: 'mt-8 md:mt-10',
	Double: 'mt-14 md:mt-20',
};

export function getEditorMargin(
	editorMargin: { margin?: EditorMargin } | EditorMargin | undefined,
	fallback: EditorMargin = 'Double',
): string | undefined {
	const key = is.string(editorMargin) ? editorMargin : editorMargin?.margin;
	return MARGINS[key || fallback] || undefined;
}
