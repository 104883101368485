import type { UserMachineState } from 'state-machines/authentication';

export const selectIsLoggingOut = (state: UserMachineState) =>
	// waitForRefresh can be during login as well, but this selector will
	// hopefully only be used in situations where the user is already logged in,
	// meaning the 'currently logging in' state won't be relevant anyway.
	state.hasTag('logout') || state.matches('delayedBrowserRefresh');

export const selectUsername = (state: UserMachineState) =>
	state.context?.userData?.username;

export const selectCustomerType = (state: UserMachineState) =>
	state.context?.userData?.customerType;

export const selectCustomerContactId = (state: UserMachineState) =>
	state.context?.userData?.customerContactId;

export const selectContactRole = (state: UserMachineState) =>
	state.context?.userData?.contactRole;

export const selectIsAuthenticatedUser = (state: UserMachineState) =>
	state.matches('authenticatedUser');

export const selectIsAuthenticatedAnonymous = (state: UserMachineState) =>
	state.matches('authenticatedAnon');

export const selectIsInitialisingUser = (state: UserMachineState) =>
	state.hasTag('initialisation');
