// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconBonusCoupon(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M19 21v-2h-2v-2h2v2h2v2zm-4 0v-2h2v2zM3 21v-8h8v8zm2-2h4v-4H5zm8 0v-2h2v2zm6-2v-2h2v2zm-4 0v-2h2v2zm2-2v-2h2v2zm-4 0v-2h2v2zm0-4V3h8v8zm2-2h4V5h-4zM3 11V3h8v8zm2-2h4V5H5z" />
		</svg>
	);
}
IconBonusCoupon.displayName = 'IconBonusCoupon';
