import { ActorRefFrom, assign, createMachine, sendParent } from 'xstate';
import { pure } from 'xstate/lib/actions';

import type { CustomerInformation } from 'models/api';
import fetchData, { API_URL } from 'utils/fetchData';
import { pushToGTM } from 'utils/GoogleTagManager';

import type { GTMMachineContext, GTMMachineEvents } from './gtm.types';

export const gtmMachine = createMachine(
	{
		id: 'gtm',
		initial: 'idle',
		context: {
			shouldPushLogin: false,
			shouldPushLoginRenewal: false,
		},
		tsTypes: {} as import('./gtm.machine.typegen').Typegen0,
		schema: {} as {
			context: GTMMachineContext;
			events: GTMMachineEvents;
			services: {
				getVoyadoId: {
					data: CustomerInformation | undefined;
				};
			};
		},
		predictableActionArguments: true,
		description:
			'The handling of the voyado id is a special case for now as it is not available in all customer token. Related documentation can be found here: https://jula.atlassian.net/wiki/spaces/JWE/pages/2618589193/Login+Events+-+Voyado+ID',
		states: {
			idle: {
				on: {
					LOGIN: [
						{
							target: 'pushLogin',
							cond: 'hasVoyadoId',
						},
						{
							target: 'getVoyadoId',
							actions: 'setShouldPushLogin',
						},
					],
					LOGIN_RENEWAL: [
						{
							target: 'pushLoginRenewal',
							cond: 'hasVoyadoId',
						},
						{
							target: 'getVoyadoId',
							actions: 'setShouldPushLoginRenewal',
						},
					],
				},
			},
			getVoyadoId: {
				invoke: {
					src: 'getVoyadoId',
					onDone: [
						{
							actions: ['setVoyadoId'],
							target: 'pushLogin',
							cond: 'shouldPushLogin',
						},
						{
							actions: ['setVoyadoId'],
							target: 'pushLoginRenewal',
							cond: 'shouldPushLoginRenewal',
						},
					],
					onError: {
						target: 'idle',
						actions: ['setVoyadoId'],
					},
				},
			},
			pushLogin: {
				entry: ['pushLogin'],
				exit: ['loginPushDone', 'resetShouldPushLogin'],
				always: 'idle',
			},
			pushLoginRenewal: {
				entry: ['pushLoginRenewal'],
				exit: ['resetShouldPushLoginRenewal'],
				always: 'idle',
			},
		},
	},
	{
		actions: {
			setVoyadoId: assign({
				voyadoId: (_, event) => {
					if (event.type === 'error.platform.gtm.getVoyadoId:invocation[0]')
						return 'null';
					if (!event.data) return 'null';
					return event.data.voyadoContactId || 'null';
				},
			}),
			loginPushDone: pure((_context) =>
				sendParent({ type: 'GTM_LOGIN_PUSH_DONE' }),
			),
			setShouldPushLogin: assign({
				shouldPushLogin: (_context) => true,
			}),
			resetShouldPushLogin: assign({
				shouldPushLogin: (_context) => false,
			}),
			pushLogin: (context) => {
				pushToGTM({
					type: 'login',
					payload: {
						voyadoId: context.voyadoId!,
					},
				});
			},
			setShouldPushLoginRenewal: assign({
				shouldPushLoginRenewal: (_context) => true,
			}),
			resetShouldPushLoginRenewal: assign({
				shouldPushLoginRenewal: (_context) => false,
			}),
			pushLoginRenewal: (context) => {
				pushToGTM({
					type: 'login_renewal',
					payload: {
						voyadoId: context.voyadoId!,
					},
				});
			},
		},
		guards: {
			hasVoyadoId: (context) => !!context.voyadoId,
			shouldPushLogin: (context) => context.shouldPushLogin,
			shouldPushLoginRenewal: (context) => context.shouldPushLoginRenewal,
		},
		services: {
			getVoyadoId: async (context, _event) =>
				fetchData<CustomerInformation>(`${API_URL}Customer`),
		},
	},
);
export type GTMMachineActor = ActorRefFrom<typeof gtmMachine>;
