import { Props as PriceProps } from 'components/Price';
import type { Price, PriceTheme, PriceType } from 'models/price';
import { i18n } from 'utils/i18n';

/** Get the Price component theme based of the price type data. */
const getThemeFromPriceType = (priceType: PriceType): PriceTheme => {
	switch (priceType) {
		case 'Sale':
		case 'Clearance':
		case 'Campaign':
			return 'campaign';
		case 'JulaClub':
			return 'julaClub';
		case 'JulaPro':
			return 'julaPro';
		case 'JulaClubsmart':
			return 'julaClubSmart';
		case 'Base':
			return 'regular';
		default:
			return 'regular';
	}
};

const getPriceLabelFromPriceType = (priceType: PriceType) => {
	switch (priceType) {
		case 'JulaClub':
			return 'JulaClub';
		case 'JulaClubsmart':
			return 'JulaClub Smart';
		case 'JulaPro':
			return 'JulaPro';
		default:
			return undefined;
	}
};

// TODO: Think about `hideSave` and `supercheap` parameters, most calls now
// looks like {...getPriceProps(product.listPrice, false, checkSupercheap(product))}
// which feels a bit clunky.
/** Returns props for the Price component based on API price data. */
export const getPriceProps = (
	price: Price | undefined,
	hideSave: boolean = false,
	supercheap: boolean = false,
): PriceProps => {
	if (!price?.priceIncVat) {
		return {
			price: '',
			currency: '',
		};
	}
	const priceProps: PriceProps = {
		price: price.priceIncVat.displayValue,
		currency: price.priceIncVat.displaySymbol,
		theme: supercheap ? 'regular' : getThemeFromPriceType(price.priceType),
	};

	// Handle decimals
	if (price.priceIncVat.displayValue.includes('.')) {
		const priceSplit = price.priceIncVat.displayValue.split('.');
		let decimals: number;

		decimals = Number.parseInt(priceSplit[1] || '0', 10);
		if (decimals && decimals < 10) {
			decimals *= 10;
		}

		priceProps.price = priceSplit[0] || '';
	}

	// Handle save
	if (price.saveIncVat && price.saveIncVat.displayValue !== '0' && !hideSave) {
		priceProps.tagBottom = `${i18n.t('product_pricetag_save')} ${
			price.saveIncVat.displayValue
		}${price.saveIncVat.displaySymbol}`;
	}

	// if (supercheap) {
	// 	priceProps.tagBottom = `${i18n.t('product_pricetag_supercheap')}`;
	// }

	priceProps.priceLabel = getPriceLabelFromPriceType(price.priceType);

	if (price.fromPrice) {
		priceProps.priceLabel = `${i18n.t('product_pricetag_from')}`;
		priceProps.priceLabelAlign = 'left';
		priceProps.tagTop = getPriceLabelFromPriceType(price.priceType);
	}

	return priceProps;
};
