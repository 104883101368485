import { useCallback } from 'react';

import { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';

type StorageKey = 'GTM_LIST_DATA';

type StorageValueForType<T> = Omit<Extract<StorageValue, { type: T }>, 'type'>;

// Extract<T>, and us wants a union and this might contain multiple types
// in the future so we need to disable the interface transformation here
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type StorageValue = {
	type: 'GTM_LIST_DATA';
	gtmItemListId: GTMItemListId;
	gtmItemListName: GTMItemListName;
};

interface UseTemporaryStoredValue<T> {
	getStoredValue: () => StorageValueForType<T> | undefined;
	setStoredValue: (newValue: StorageValueForType<T>) => void;
}

/**
 * Hook to store a value in localStorage temporarily.
 */
export function useTemporaryStoredValue<T extends StorageKey>(
	key: StorageKey,
): UseTemporaryStoredValue<T> {
	const setStoredValue = useCallback(
		(newValue: unknown) => {
			if (typeof localStorage !== 'undefined') {
				localStorage.setItem(key, JSON.stringify(newValue));
			}
		},
		[key],
	);
	const getStoredValue = useCallback((): StorageValueForType<T> | undefined => {
		if (typeof localStorage === 'undefined') return undefined;
		const storedValue = localStorage.getItem(key);
		localStorage.removeItem(key);
		if (!storedValue) return undefined;
		try {
			return JSON.parse(storedValue) || undefined;
		} catch {
			return undefined;
		}
	}, [key]);

	return {
		setStoredValue,
		getStoredValue,
	};
}
