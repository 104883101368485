// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconCopyLink(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M7.52 21.687h-.142a5.519 5.519 0 0 1-3.666-9.517l2.48-2.363a5.524 5.524 0 0 1 7.8.194 5.5 5.5 0 0 1 1.021 1.553 1 1 0 0 1-1.824.819 3.5 3.5 0 0 0-.646-.993 3.524 3.524 0 0 0-4.975-.124L5.092 13.62a3.519 3.519 0 0 0 2.336 6.067 3.42 3.42 0 0 0 2.514-.97l2.45-2.33a1 1 0 0 1 .076-.079 1 1 0 0 1 1.416 1.41 1 1 0 0 1-.092.1l-2.474 2.355a5.48 5.48 0 0 1-3.798 1.514" />
			<path d="M14.144 15.374a5.5 5.5 0 0 1-4.033-1.748 5.4 5.4 0 0 1-1.008-1.561 1 1 0 0 1 1.832-.8 3.5 3.5 0 0 0 .636 1 3.526 3.526 0 0 0 4.974.167l2.5-2.342a3.518 3.518 0 1 0-4.806-5.14l-2.47 2.31-.074.076a1 1 0 0 1-1.412-1.415l.092-.1 2.5-2.34a5.518 5.518 0 1 1 7.536 8.062l-2.5 2.342a5.5 5.5 0 0 1-3.767 1.489" />
		</svg>
	);
}
IconCopyLink.displayName = 'IconCopyLink';
