import { useEffect } from 'react';
import type { RefObject } from 'react';

import { is } from 'utils/helpers';

/**
 * Trigger a callback when clicking outside of the specified container.
 */
export function useOutsideClick<T extends HTMLElement>(
	containerRef: RefObject<T>,
	callback: (e: MouseEvent) => void,
) {
	const handleClick = (e: MouseEvent) => {
		// If offsetParent is null ref-element is hidden and we should not act
		if (
			containerRef.current &&
			is.node(e.target) &&
			!containerRef.current.contains(e.target) &&
			containerRef.current.offsetParent
		) {
			callback(e);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	});
}
