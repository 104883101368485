// IMPORTANT! Keep in sync with tailwind.config.js
export const breakpoints = {
	xs: 0,
	sm: 600,
	headerRow: 750, // Component specific
	md: 1024,
	lg: 1366,
	xl: 1644,
	full: 1920,
} as const;

export type Breakpoint = keyof typeof breakpoints;
