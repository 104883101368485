import { Cart, CartRequest } from 'models/api';
import { filterObject } from 'utils/collection';
import { is } from 'utils/helpers';

/** Helper function to extract discount codes. */
export function getDiscountCodes(cart: Cart) {
	return [
		cart.freightDiscount?.checkNumber,
		...(cart.bonusDiscounts?.map((bd) => bd.checkNumber) ?? []),
		cart.campaignDiscount?.checkNumber,
	].filter(is.truthy);
}

/** Returns a Cart object formatted for request (removes unnecessary attributes). */
export function formatCartForRequest(cart: Cart): CartRequest {
	return filterObject(
		{
			variants: cart.variants?.map((variant) => ({
				variantId: variant.id,
				quantity: variant.qty,
				customizationId: variant.customization?.id,
				metaData: variant.metaData,
			})),
			selectedContactReferenceId: cart.selectedContactReference?.id,
			giftCards: cart.giftCards,
			selectedDelivery: cart.selectedDelivery,
			selectedPayment: cart.selectedPayment,
			email: cart.email,
			useAlternativeDeliveryAddress: cart.alternativeDeliveryAddress,
			telephoneNumber: cart.telephoneNumber,
			deliveryAddress: cart.deliveryAddress,
			customerAddress: cart.customerAddress,
			unregisteredCompanyInfo: cart.unregisteredCompanyInfo,
			// @ts-expect-error TODO: this error needs to be fixed all the way back in the giftCardBonus machine
			discountCodes: cart.discountCodes || getDiscountCodes(cart),
			allowAnonymousReviews: cart.allowAnonymousReviews,
		},
		(val) => val !== undefined,
	);
}
