// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconCheck(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m9.83 15.78-.7-.7-2.84-2.84a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l2.83 2.83 6.36-6.37a1 1 0 0 1 1.42 0 1 1 0 0 1 0 1.42l-6.37 6.36-.7.7a1 1 0 0 1-.71.3 1 1 0 0 1-.71-.3z" />
		</svg>
	);
}
IconCheck.displayName = 'IconCheck';
