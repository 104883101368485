import clsx, { ClassValue } from 'clsx';
import { extendTailwindMerge, validators } from 'tailwind-merge';

import { is } from './helpers';

const { isArbitraryValue, isInteger } = validators;

function isName(value: unknown) {
	return is.string(value) && value.length > 0;
}

const customTwMerge = extendTailwindMerge({
	extend: {
		classGroups: {
			// Add isName validator to recognize custom z-index values
			// that aren't numbers.
			z: [{ z: ['auto', isInteger, isArbitraryValue, isName] }],
		},
	},
});

export default function cn(...args: ClassValue[]): string {
	return customTwMerge(clsx(...args));
}
