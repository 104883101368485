// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconMenu(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<rect width="20" height="2" rx="1" transform="translate(2 4)" />
			<rect width="20" height="2" rx="1" transform="translate(2 11)" />
			<rect width="20" height="2" rx="1" transform="translate(2 18)" />
		</svg>
	);
}
IconMenu.displayName = 'IconMenu';
