import { useEffect, useRef, useState } from 'react';
import type { RefObject } from 'react';

/**
 * Observe if a `position: sticky` element is currently stuck. Assumes it's
 * positioned from the top of the screen.
 *
 * @example
 *
 * const [containerRef, isStuck] = useIsStickyStuck<HTMLDivElement>();
 *
 * return (
 *   <div ref={containerRef} className={clsx('sticky top-0', isStuck && 'stuck')}>
 *     ...
 *   </div>
 * );
 */
export function useIsStickyStuck<T extends HTMLElement>(
	topPos: number = 0,
): [RefObject<T>, boolean] {
	const elementRef = useRef<T>(null);
	const [isStuck, setIsStuck] = useState<boolean>(false);
	useEffect(() => {
		// Invert positive/negative and add the pixel that makes it work.
		const topVal = -(topPos + 1);
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry) {
					setIsStuck(entry.intersectionRatio < 1);
				}
			},
			{ threshold: [1], rootMargin: `${topVal}px 0px 0px 0px` },
		);
		if (elementRef.current) {
			observer.observe(elementRef.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [topPos]);

	return [elementRef, isStuck] as const;
}
