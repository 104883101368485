// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFilter(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M7 7H5a1 1 0 0 0 0 2h2v1a1 1 0 0 0 2 0V9h10a1 1 0 0 0 0-2H9V6a1 1 0 0 0-2 0zm8 8H5a1 1 0 0 0 0 2h10v1a1 1 0 0 0 2 0v-1h2a1 1 0 0 0 0-2h-2v-1a1 1 0 0 0-2 0z" />
		</svg>
	);
}
IconFilter.displayName = 'IconFilter';
