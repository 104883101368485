// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconSupport(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M21 12.22a9.04 9.04 0 0 0-9-8.97c-4.94 0-9 4.06-9 9v.03c-.62.35-1 1-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.84 3.16-7 7-7s7 3.16 7 7V19h-8v2h8a2 2 0 0 0 2-2v-1.22c.61-.32 1-.95 1-1.64v-2.3c0-.68-.4-1.3-1-1.62" />
			<circle cx="9" cy="13" r="1" />
			<circle cx="15" cy="13" r="1" />
			<path d="M18 11.03a6.06 6.06 0 0 0-5.96-5.04 6.07 6.07 0 0 0-6.02 6.46 8.08 8.08 0 0 0 4.86-5.89A8.05 8.05 0 0 0 18 11.03" />
		</svg>
	);
}
IconSupport.displayName = 'IconSupport';
