import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

export const ZD_MESSENGER_HAS_OPENED_SESSION_STORAGE_KEY =
	'ZD-messengerHasOpened';

export interface ChatContextType {
	hasOpenedMessenger: boolean;
	messageCount: number;
	messengerIsOpen: boolean;
	setHasOpenedMessenger: () => void;
	setMessageCount: (count: number) => void;
	setMessengerIsOpen: (isOpen: boolean) => void;
}
export const ChatContext = createContext<ChatContextType>({} as any);

export function ChatContextProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const [messengerIsOpen, setMessengerIsOpen] = useState(false);
	const [messageCount, setMessageCount] = useState(0);
	const [hasOpened, setHasOpened] = useState(false);

	const setHasOpenedMessenger = useCallback(() => {
		window.sessionStorage.setItem(
			ZD_MESSENGER_HAS_OPENED_SESSION_STORAGE_KEY,
			'true',
		);
		setHasOpened(true);
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setHasOpened(
				Boolean(
					window.sessionStorage.getItem(
						ZD_MESSENGER_HAS_OPENED_SESSION_STORAGE_KEY,
					),
				),
			);
		}
	}, []);

	const value = useMemo(
		() => ({
			hasOpenedMessenger: hasOpened,
			setHasOpenedMessenger,
			messageCount,
			setMessageCount,
			messengerIsOpen,
			setMessengerIsOpen,
		}),
		[hasOpened, messageCount, messengerIsOpen, setHasOpenedMessenger],
	);

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

ChatContextProvider.displayName = 'ChatContextProvider';

export function useChatContext() {
	return useContext(ChatContext);
}

ChatContext.displayName = 'ChatContext';
