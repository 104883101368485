// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconYoutube(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M23.844 6.976a3.644 3.644 0 0 0-3.469-3.807C17.775 3.047 15.129 3 12.424 3h-.844c-2.7 0-5.354.047-7.951.169A3.65 3.65 0 0 0 .164 6.985C.047 8.654-.005 10.323 0 11.992S.047 15.33.159 17a3.657 3.657 0 0 0 3.465 3.821c2.728.127 5.527.183 8.373.178s5.64-.047 8.373-.178A3.657 3.657 0 0 0 23.839 17c.113-1.674.164-3.343.159-5.016s-.042-3.335-.154-5.008M9.706 16.591V7.379l6.8 4.6z" />
		</svg>
	);
}
IconYoutube.displayName = 'IconYoutube';
