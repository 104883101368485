// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconArrow(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m9.3 7.2 4.94 4.96L9.3 17.1a1 1 0 0 0 0 1.41 1 1 0 0 0 1.42 0l5.65-5.66a1 1 0 0 0 0-1.41l-5.65-5.66a1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.42" />
		</svg>
	);
}
IconArrow.displayName = 'IconArrow';
