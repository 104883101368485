// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconShoppinglistAdd(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M18 13a6.94 6.94 0 0 1-3.587-1H8a1 1 0 0 1 0-2h4.264a6.94 6.94 0 0 1-1.184-3H9a2 2 0 0 1-2-2V3H6a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.3a7 7 0 0 1-2 .3m-2 5H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2" />
			<path d="M18 0a6 6 0 1 0 6 6 6 6 0 0 0-6-6m3 7h-2v2a1 1 0 0 1-2 0V7h-2a1 1 0 0 1 0-2h2V3a1 1 0 0 1 2 0v2h2a1 1 0 0 1 0 2" />
			<path d="M12 1a2 2 0 0 0-1.723 1H9a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a6.97 6.97 0 0 1 1.893-4.772A1.9 1.9 0 0 0 12 1" />
		</svg>
	);
}
IconShoppinglistAdd.displayName = 'IconShoppinglistAdd';
