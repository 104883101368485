import { LoginOption } from 'components/Login';
import { publicRuntimeConfig } from 'config';
import { ConnectCustomerType } from 'models/connect/oauth';
import { empty } from 'utils/helpers';
import { i18n } from 'utils/i18n';

const getAllowedLoginOptions = (
	customerType: ConnectCustomerType,
): string[] => {
	if (customerType === 'Club Staff') {
		return (
			publicRuntimeConfig?.NEXT_PUBLIC_CONNECT_JULACLUB_LOGIN_OPTIONS?.split(
				',',
			) || empty.array
		);
	}

	if (customerType === 'Pro') {
		return (
			publicRuntimeConfig?.NEXT_PUBLIC_CONNECT_JULAPRO_LOGIN_OPTIONS?.split(
				',',
			) || empty.array
		);
	}

	return empty.array;
};

export function getLoginOptions(
	customerType: ConnectCustomerType,
	loginOptions?: LoginOption[],
): LoginOption[] {
	const defaultOptions: LoginOption[] = [
		{
			method: 'bank_id_se',
			text: i18n.t('account_login_bank_id_button'),
			icon: 'bankIdSe',
		},
		{
			method: 'bank_id_fi',
			text: i18n.t('account_login_bank_id_button'),
		},
		{
			method: 'bank_id_no',
			text: i18n.t('account_login_bank_id_button'),
			icon: 'bankIdNo',
		},
		{
			method: 'otp_sms',
			text: i18n.t('account_login_sms_code_button'),
		},
		{
			method: 'otp_email',
			text: i18n.t('account_login_email_code_button'),
		},
	];
	return (loginOptions || defaultOptions).filter((opt) =>
		getAllowedLoginOptions(customerType).includes(opt.method),
	);
}
