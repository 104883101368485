// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconClear(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 22C6.52 22 2 17.48 2 12S6.52 2 12 2s10 4.52 10 10-4.52 10-10 10m0-8.6 2.8 2.8a.99.99 0 1 0 1.4-1.4L13.4 12l2.8-2.8a.98.98 0 0 0 0-1.4.99.99 0 0 0-1.4 0L12 10.6 9.2 7.8a.98.98 0 0 0-1.4 0 1 1 0 0 0 0 1.4l2.8 2.8-2.8 2.8a.99.99 0 1 0 1.4 1.4z" />
		</svg>
	);
}
IconClear.displayName = 'IconClear';
