import { useEffect, useRef } from 'react';

import { useEffectOnce } from './useEffectOnce';

/** Run an effect only when the component unmounts. */
export function useUnmount(fn: () => void) {
	// Keep the callback from the current render in a ref. If used directly it
	// would always be the first version due to closure.
	const savedFn = useRef<typeof fn>(fn);
	useEffect(() => {
		savedFn.current = fn;
	}, [fn]);

	useEffectOnce(() => () => savedFn.current());
}
