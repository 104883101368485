// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconBonusChart(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 18.999a7 7 0 0 0 1-13.928v7.928H5.071a7.01 7.01 0 0 0 6.929 6m0 2a9.007 9.007 0 0 1-8.945-10H11V3.055a9 9 0 0 1 1-.056 9 9 0 1 1 0 18" />
		</svg>
	);
}
IconBonusChart.displayName = 'IconBonusChart';
