import { is } from 'utils/helpers';

import { WishlistMachineState } from './wishlist.machine';

export const selectWishlist = (state: WishlistMachineState) =>
	state.context.wishlistData;

export const selectSharedWishlist = (state: WishlistMachineState) =>
	state.context.sharedWishlistData;

export const selectWishlistQuantity = (state: WishlistMachineState) =>
	state.context.productQuantity;

export const selectIsLoading = (state: WishlistMachineState) =>
	state.hasTag('loading');

export const selectIsInitialLoading = (state: WishlistMachineState) =>
	state.hasTag('loading') && !state.context.wishlistData;

export const selectWishlistButtonState = (state: WishlistMachineState) =>
	state.context.buttonState;

export const selectWishlistAdditionalSales = (state: WishlistMachineState) =>
	state.context.additionalSales;

export const selectWishlistAdditionalSalesIsOpen = (
	state: WishlistMachineState,
) => state.context.additionalSalesIsOpen;

export const selectWishlistHasVariants = (state: WishlistMachineState) =>
	is.arrayWithLength(state.context.wishlistData?.categories);
