// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconCart(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M20.78 14.24 22.4 6.2l.02-.2a1 1 0 0 0-1-1H4.82l-.2-2.09a1 1 0 0 0-1-.91h-2a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1.1l.9 10a5.77 5.77 0 0 0 1.64 4.16A2.97 2.97 0 0 0 4.63 20c0 1.65 1.36 3 3 3a3.01 3.01 0 0 0 2.82-4h4.36a3.01 3.01 0 0 0 2.82 4c1.65 0 3-1.35 3-3s-1.35-3-3-3h-10a1.7 1.7 0 0 1-1.58-1.09l13.8-.86a1 1 0 0 0 .93-.8z" />
		</svg>
	);
}
IconCart.displayName = 'IconCart';
