import { useEffect, useState } from 'react';

import { useDebouncedCallback } from './useDebouncedCallback';

/**
 * Get on-screen keyboard size where it doesn't affect the layout, like in
 * iOS Safari and Android Chrome 108+.
 *
 * https://developer.chrome.com/blog/viewport-resize-behavior/
 *
 * TODO: probably want to replace with `interactive-widget=resizes-content`
 * in viewport tag or with `keyboard-inset-*` CSS env() variables from the
 * VirtualKeyboard API when support is better, to have something declarative
 * handled by the browser instead.
 * https://developer.mozilla.org/en-US/docs/Web/API/VirtualKeyboard_API
 */
export function useVirtualKeyboardSize() {
	const [size, setSize] = useState<number>(0);
	const viewport = globalThis.visualViewport;

	const [handleResize] = useDebouncedCallback(
		() => {
			const visualViewportDiff =
				viewport?.height && viewport.height < globalThis.innerHeight
					? Math.round(globalThis.innerHeight - viewport.height)
					: 0;
			// Can sometimes diff by a few pixels, ignore it then.
			setSize(visualViewportDiff > 10 ? visualViewportDiff : 0);
		},
		50,
		[viewport],
	);

	useEffect(() => {
		viewport?.addEventListener('resize', handleResize);

		return () => {
			viewport?.removeEventListener('resize', handleResize);
		};
	}, [handleResize, viewport]);

	return size;
}
