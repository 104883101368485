import type { CartError } from 'models/api';

import { CheckoutMachineState } from './checkout.machine';

export const selectPaymentActor = (state: CheckoutMachineState) =>
	state.context.paymentActor;

export const selectDeliveryActor = (state: CheckoutMachineState) =>
	state.context.deliveryActor;

export const selectUserInfoActor = (state: CheckoutMachineState) =>
	state.context.userInfoActor;

export const selectGiftCardBonusActor = (state: CheckoutMachineState) =>
	state.context.giftCardBonusActor;

export const selectContactReferencesActor = (state: CheckoutMachineState) =>
	state.context.contactReferencesActor;

export const selectCartIsReady = (state: CheckoutMachineState) =>
	!state.matches('loadCart');

export const selectCartHasLoaded = (state: CheckoutMachineState) =>
	Boolean(state.context.data?.id);

export const selectCartData = (state: CheckoutMachineState) =>
	state.context.data;

export const selectIsInErrorState = (state: CheckoutMachineState) =>
	state.matches('error');

export const selectAllowGiftCard = (state: CheckoutMachineState) =>
	Boolean(state.context.data?.allowGiftCard);

export const selectPayButtonState = (state: CheckoutMachineState) =>
	state.context.payButtonState;

export const selectVariants = (state: CheckoutMachineState) =>
	state.context.data?.variants;

export const selectCheckoutStatus = (state: CheckoutMachineState) =>
	state.context.data?.checkoutStatus;

export const selectShowNotification = (state: CheckoutMachineState) =>
	state.context.showNotification;

export const selectAmountToPay = (state: CheckoutMachineState): string => {
	const amountToPay = state.context.data?.summaries?.filter(
		(sum) => sum.sumType === 'AmountToPay',
	)[0]?.displayValue;
	return amountToPay || '';
};

export const selectAmountToPayCurrencySymbol = (
	state: CheckoutMachineState,
): string => {
	const amountToPayCurrencySymbol = state.context.data?.summaries?.filter(
		(sum) => sum.sumType === 'AmountToPay',
	)[0]?.displaySymbol;
	return amountToPayCurrencySymbol || '';
};

export const selectAmountToPayValue = (state: CheckoutMachineState) =>
	state.context.data?.summaries?.filter(
		(sum) => sum.sumType === 'AmountToPay',
	)[0]?.value;

export const selectSummaries = (state: CheckoutMachineState) =>
	state.context.data?.summaries;

export const selectSavingTotalSumSummary = (state: CheckoutMachineState) =>
	state.context.data?.savingSummaries.find(
		(summary) => summary.sumType === 'SavingTotalSum',
	);

export const selectErrorList = (
	state: CheckoutMachineState,
): CartError[] | undefined => state.context.data?.errorList;

export const selectEmailValidationError = (state: CheckoutMachineState) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.type === 'EmailInvalid',
	);

export const selectTelephoneNumberValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.type === 'TelephoneNumberInvalid',
	);

export const selectDeliveryAddressFirstNameValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'deliveryAddress.firstName',
	);

export const selectCustomerAddressFirstNameValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'customerAddress.firstName',
	);

export const selectDeliveryAddressLastNameValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'deliveryAddress.lastName',
	);

export const selectCustomerAddressLastNameValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'customerAddress.lastName',
	);

export const selectDeliveryAddressValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'deliveryAddress.address',
	);

export const selectCustomerAddressValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'customerAddress.address',
	);

export const selectDeliveryAddressCoValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'deliveryAddress.coAddress',
	);

export const selectCustomerAddressCoValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'customerAddress.coAddress',
	);

export const selectDeliveryAddressPostalCodeValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'deliveryAddress.postalCode',
	);

export const selectCustomerAddressPostalCodeValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'customerAddress.postalCode',
	);

export const selectDeliveryAddressCityValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'deliveryAddress.city',
	);

export const selectCustomerAddressCityValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) => error.key === 'customerAddress.city',
	);

export const selectUnregisteredCompanyNameValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) =>
			error.type === 'UnregisteredCompanyInfoCompanyNameInvalidLength' ||
			error.type === 'UnregisteredCompanyInfoCompanyNameInvalidChar',
	);

export const selectUnregisteredCompanyVatNumberValidationError = (
	state: CheckoutMachineState,
) =>
	state?.context?.data?.errorList?.filter(
		(error) =>
			error.type ===
				'UnregisteredCompanyInfoVatRegistrationNumberInvalidChar' ||
			error.type ===
				'UnregisteredCompanyInfoVatRegistrationNumberInvalidLength',
	);

export const selectCheckoutErrorMessages = (state: CheckoutMachineState) =>
	state.context.checkoutErrorMessages;
