// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconParcel(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M15.531 4.569 12 3 3 7l3.83 1.7z" />
			<path d="m16.735 5.1-8.7 4.133L12 11l9-4z" />
			<path d="M12.5 20.778 21 17V8l-8.5 3.778z" />
			<path d="m3 17 8.5 3.778v-9L3 8z" />
		</svg>
	);
}
IconParcel.displayName = 'IconParcel';
