// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconCompare(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m14.695 15.418-4.187-3.349a.313.313 0 0 0-.508.244v2.349H3a1 1 0 0 0 0 2h7v2.349a.313.313 0 0 0 .508.244l4.187-3.349a.313.313 0 0 0 0-.488" />
			<path d="M21 6.662h-7V4.313a.313.313 0 0 0-.508-.244L9.305 7.418a.313.313 0 0 0 0 .488l4.187 3.349a.313.313 0 0 0 .508-.244V8.662h7a1 1 0 0 0 0-2" />
		</svg>
	);
}
IconCompare.displayName = 'IconCompare';
