import { useEffect, useState } from 'react';

/**
 * Helper that checks if testmode is enabled
 * (by looking at the "testmode" cookie)
 */
export function isTestmode(): boolean {
	return (
		typeof document !== 'undefined' &&
		document.cookie?.split('; ').indexOf('testmode=true') !== -1
	);
}

/**
 * Hook that will tell if testmode is active
 */
export function useTestmode(): boolean {
	const [testmode, setTestmode] = useState<boolean>(false);

	useEffect(() => {
		setTestmode(isTestmode());
	}, []);

	return testmode;
}
