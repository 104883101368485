import { useEffect, useState } from 'react';

/**
 * Helper that checks if devmode is enabled
 * (by looking at the "devmode" cookie)
 */
export function isDevmode(): boolean {
	return (
		typeof document !== 'undefined' &&
		document.cookie?.split('; ').indexOf('devmode=true') !== -1
	);
}

/**
 * Hook that will tell if devmode is active
 */
export function useDevmode(): boolean {
	const [devmode, setDevmode] = useState<boolean>(false);

	useEffect(() => {
		setDevmode(isDevmode());
	}, []);

	return devmode;
}
