// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconBike(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="m18.18 10-1.7-4.68A2.01 2.01 0 0 0 14.6 4H12v2h2.6l1.46 4h-4.81l-.36-1H12V7H7v2h1.75l1.82 5H9.9a4.93 4.93 0 0 0-4.65-3.99A5.07 5.07 0 0 0 0 15a4.95 4.95 0 0 0 5 5 4.93 4.93 0 0 0 4.9-4h4.2a4.93 4.93 0 0 0 4.65 3.99 5.077 5.077 0 0 0 5.25-5 4.95 4.95 0 0 0-5-5h-.82zM7.82 16A2.95 2.95 0 0 1 5 18a3 3 0 0 1 0-6 2.95 2.95 0 0 1 2.82 2H5v2zm6.28-2h-1.4l-.73-2H15a4.7 4.7 0 0 0-.9 2m4.9 4a3 3 0 0 1-1.95-5.28l.96 2.64 1.88-.68-.97-2.67c.03 0 .06-.01.09-.01a3 3 0 1 1-.01 6" />
		</svg>
	);
}
IconBike.displayName = 'IconBike';
