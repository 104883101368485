import { useEffect, useRef, useState } from 'react';

import { useInterval, useTimeout } from '.';

/**
 * Run an effect at a timed interval, with a transition time at each tick.
 * Can be paused by setting a `null` delay.
 *
 * @example
 *
 * function Component() {
 *   // Every third second isTransitioning will flip to true, then 200 ms later
 *   // back to false and the effect runs.
 *   const isTransitioning = useTransitionInterval(() => {
 *     // Transition is done here
 *   }, 3000, 200);
 *
 *   return null;
 * }
 */
export function useTransitionInterval(
	effect: () => void,
	intervalDelay: number | null,
	transitionDuration: number,
): boolean {
	const savedEffect = useRef<typeof effect>(effect);
	const [isTransitioning, setIsTransitioning] = useState(false);

	// Callback from the current render.
	useEffect(() => {
		savedEffect.current = effect;
	}, [effect]);

	useInterval(() => {
		setIsTransitioning(true);
	}, intervalDelay);
	useTimeout(
		() => {
			savedEffect.current();
			setIsTransitioning(false);
		},
		isTransitioning ? transitionDuration : null,
	);

	return isTransitioning;
}
