// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconPause(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M14.57 17.5v-11c0-.82.68-1.5 1.5-1.5.83 0 1.5.68 1.5 1.5v11c0 .82-.67 1.5-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5" />
			<path d="M6.45 17.5v-11c0-.82.68-1.5 1.5-1.5.83 0 1.5.68 1.5 1.5v11c0 .82-.67 1.5-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5" />
		</svg>
	);
}
IconPause.displayName = 'IconPause';
