/**
 * DevModeBanner
 */

import React from 'react';
import { inspect } from '@xstate/inspect';

import Button from 'components/Button';
import InfoBox from 'components/InfoBox';
import { isDevmode, useDevmode, useTestmode } from 'hooks';
import { openPageInLayoutservice } from 'utils/devtools';

if (isDevmode()) {
	import(`utils/devtools`)
		.then((module) => {
			module.layoutServiceUrlViewer();
		})
		// eslint-disable-next-line unicorn/prefer-top-level-await
		.catch((error) => {
			console.log(error.message);
		});

	inspect({
		url: 'https://stately.ai/viz?inspect',
		iframe: false,
	});
}

export default function DevModeBanner() {
	const isTest = useTestmode();
	const isDev = useDevmode();

	if (!isTest && !isDev) {
		return null;
	}

	return (
		<div className="divide-y">
			{isTest && (
				<InfoBox icon="info" heading="Testmode active" variant="information">
					<Button size="small" variant="primary" href="/api/testmode/leave/">
						Exit testmode
					</Button>
				</InfoBox>
			)}
			{isDev && (
				<InfoBox icon="info" heading="Devmode active" variant="information">
					<div className="flex space-x-3">
						<Button
							size="small"
							variant="cta"
							onClick={() => {
								openPageInLayoutservice();
							}}
						>
							View page in layoutservice
						</Button>
						<Button size="small" variant="primary" href="/api/dev/leave/">
							Exit devmode
						</Button>
					</div>
				</InfoBox>
			)}
		</div>
	);
}
DevModeBanner.displayName = 'DevModeBanner';
