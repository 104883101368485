import { useEffect, useRef } from 'react';
import type { RefObject } from 'react';

import { is } from 'utils/helpers';

export type DisclosureWidgetCloseHandler = (
	event:
		| globalThis.MouseEvent
		| globalThis.FocusEvent
		| globalThis.KeyboardEvent,
) => void;

/**
 * Handles a close callback for an ARIA disclosure pattern widget.
 *
 * Closes via click outside, focus out and Escape key press. The latter two
 * are opt-out.
 *
 * https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/
 *
 * @example
 *
 * function Component() {
 *   const [isOpen, setIsOpen] = useState<boolean>(false);
 *   const handleWidgetClose = useCallback(() => {
 *     setIsOpen(false);
 *   });
 *
 *   const {
 *     onTriggerClick,
 *     onTriggerMouseDown,
 *     triggerRef,
 *     widgetRef
 *   } = useDisclosureWidget(isOpen, handleWidgetClose);
 *
 *   const handleTriggerClick = () => {
 *     setIsOpen(true);
 *     onTriggerClick();
 *   }
 *
 *   return (
 *     <button
 *       ref={triggerRef}
 *       type="button"
 *       aria-controls="my-widget"
 *       aria-expanded={isOpen}
 *       onMouseDown={onTriggerMouseDown}
 *       onClick={handleTriggerClick}
 *     >
 *       Open widget
 *     </button>
 *     <div ref={widgetRef} id="my-widget" hidden={!isOpen}>
 *       Widget content
 *     </div>
 *   );
 * }
 */
export function useDisclosureWidgetClose<
	TriggerT extends HTMLElement,
	WidgetT extends HTMLElement,
>(
	isOpen: boolean,
	onClose: DisclosureWidgetCloseHandler,
	{
		closeOnEscape = true,
		closeOnFocusOut = true,
		triggerRef: passedTriggerRef,
		widgetRef: passedWidgetRef,
	}: {
		/** If escape press should trigger a close */
		closeOnEscape?: boolean;

		/** If focus outside the widget should trigger a close */
		closeOnFocusOut?: boolean;

		/** A custom ref object for the trigger element */
		triggerRef?: RefObject<TriggerT>;

		/** A custom ref object for the targeted element */
		widgetRef?: RefObject<WidgetT>;
	} = {},
) {
	const internalTriggerRef = useRef<TriggerT>(null);
	const internalWidgetRef = useRef<WidgetT>(null);
	const triggerRef = passedTriggerRef || internalTriggerRef;
	const widgetRef = passedWidgetRef || internalWidgetRef;
	const isExplicitCloseAction = useRef<boolean>(false);

	const onTriggerMouseDown = () => {
		isExplicitCloseAction.current = true;
	};
	const onTriggerClick = () => {
		isExplicitCloseAction.current = false;
	};

	useEffect(() => {
		if (!isOpen) {
			return undefined;
		}

		const widgetRoot = widgetRef.current;
		const isOutsideRoot = (target: EventTarget | null) =>
			is.node(target) && widgetRoot && !widgetRoot.contains(target);

		const handleDocumentClick = (e: globalThis.MouseEvent) => {
			if (
				is.element(e.target) &&
				e.target.closest('button') !== triggerRef.current &&
				isOutsideRoot(e.target)
			) {
				onClose(e);
			}
		};
		const handleFocusOut = (e: globalThis.FocusEvent) => {
			// Don't trigger onClose from focus leave if focus is about to be lost
			// through an explicit action that will handle it. Examples:
			// - Pressing Escape will move focus to the trigger and run onClose,
			//   then the moved focus will trigger an additional onClose here.
			// - Having focus inside, e.g. via tabbing, then clicking the trigger
			//   with the mouse will cause a FocusOut (= onClose) then a Click
			//   which will now act on isOpen being false = opening again.
			//   Event order: MouseDown → FocusOut → MouseUp → Click.
			if (!isExplicitCloseAction.current && isOutsideRoot(e.relatedTarget)) {
				onClose(e);
			}
		};
		const handleEscape = (e: globalThis.KeyboardEvent) => {
			if (e.key === 'Escape') {
				isExplicitCloseAction.current = true;
				triggerRef.current?.focus();
				onClose(e);
			}
		};
		document.addEventListener('click', handleDocumentClick);
		if (closeOnFocusOut) {
			widgetRoot?.addEventListener('focusout', handleFocusOut);
		}
		if (closeOnEscape) {
			widgetRoot?.addEventListener('keydown', handleEscape);
		}

		return () => {
			isExplicitCloseAction.current = false;
			document.removeEventListener('click', handleDocumentClick);
			if (closeOnFocusOut) {
				widgetRoot?.removeEventListener('focusout', handleFocusOut);
			}
			if (closeOnEscape) {
				widgetRoot?.removeEventListener('keydown', handleEscape);
			}
		};
	}, [closeOnEscape, closeOnFocusOut, isOpen, onClose, triggerRef, widgetRef]);

	return {
		onTriggerClick,
		onTriggerMouseDown,
		triggerRef,
		widgetRef,
	};
}
