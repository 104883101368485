import { useCallback, useRef } from 'react';

/**
 * Get and set a ref value via callbacks.
 */
export function useRefSetter<T>(
	initialValue: T | null = null,
): [() => T | null, (value: T) => void] {
	const ref = useRef<T | null>(initialValue);

	const setRefValue = useCallback((value: T) => {
		ref.current = value;
	}, []);
	const getRefValue = useCallback(() => ref.current, []);

	return [getRefValue, setRefValue] as const;
}
