// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconStarHollow(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21zm.78-1.28-.78-.47-3.91 2.36 1.04-4.45-3.46-3 4.55-.38L12 5.85l1.78 4.2 4.55.38-3.46 3 1.04 4.45-3.13-1.9Z" />
		</svg>
	);
}
IconStarHollow.displayName = 'IconStarHollow';
