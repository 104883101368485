// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconInfo(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 22C6.52 22 2 17.48 2 12S6.52 2 12 2s10 4.52 10 10-4.52 10-10 10m-1.75-10.75a1 1 0 0 1 .68.17c.14.17.2.39.19.6 0 .19-.03.37-.06.55l-.15.61-.64 2.28a6.3 6.3 0 0 0-.16 1.2c-.01.44.18.87.52 1.16.4.32.92.49 1.45.45.36.01.72-.04 1.07-.16.33-.11.74-.27 1.23-.46l.17-.7a3 3 0 0 1-.96.23c-.24.02-.49-.04-.7-.17a.77.77 0 0 1-.2-.62 9 9 0 0 1 .2-1.15l.65-2.27c.07-.22.1-.45.13-.68l.03-.52a1.5 1.5 0 0 0-.5-1.17 2.1 2.1 0 0 0-1.43-.45c-.37 0-.74.07-1.1.19-.4.13-.81.28-1.2.44l-.17.7.44-.15c.17-.05.34-.07.5-.08ZM13.18 6c-.4 0-.79.14-1.08.42a1.36 1.36 0 0 0 0 2.02c.3.28.68.43 1.08.42.4 0 .8-.14 1.09-.42.28-.25.45-.62.44-1.01 0-.39-.16-.75-.45-1.01A1.54 1.54 0 0 0 13.18 6" />
		</svg>
	);
}
IconInfo.displayName = 'IconInfo';
