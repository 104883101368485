import { useSelectedStore } from 'contexts';
import { useStock } from 'hooks/data-fetching';
import { is } from 'utils/helpers';

export function useProductStock({
	productId,
	shouldLoadNearbyStores = false,
	shouldLoadVariantsStock = false,
	variantIds,
}: {
	productId: string | undefined;
	shouldLoadNearbyStores?: boolean;
	shouldLoadVariantsStock?: boolean;
	variantIds?: string[];
}) {
	const { isLoading: selectedStoreIsLoading, selectedStore } =
		useSelectedStore();

	const {
		error: productStockError,
		isLoading: isLoadingProductStock,
		itemsStock: productItemsStock,
	} = useStock({
		variantIds: productId,
		storeIds: selectedStore?.id,
		isActive: !selectedStoreIsLoading && Boolean(productId),
	});

	const productStock = productItemsStock?.find(
		(variant) => variant.id === productId,
	);

	const nearbyStoreIds = productStock?.storeStock?.stocks
		?.find((store) => store.store?.id === selectedStore?.id)
		?.store.nearbyStores?.map((nearbyStore) => nearbyStore.store.id)
		.filter(is.truthy);

	const {
		error: nearbyStoresStockError,
		isLoading: isLoadingNearbyStoresStock,
		itemsStock: nearbyStoresItemsStock,
	} = useStock({
		variantIds: productId,
		storeIds: nearbyStoreIds,
		isActive: shouldLoadNearbyStores && Boolean(nearbyStoreIds),
	});

	const {
		error: variantsStockError,
		isLoading: isLoadingVariantsStock,
		itemsStock: variantsStock,
	} = useStock({
		variantIds,
		storeIds: selectedStore?.id,
		isActive: shouldLoadVariantsStock,
	});

	return {
		isLoadingNearbyStoresStock,
		isLoadingProductStock,
		isLoadingVariantsStock,
		nearbyStoresStock: nearbyStoresItemsStock?.find(
			(variant) => variant.id === productId,
		)?.storeStock?.stocks,
		productStock,
		variantsStock,
	};
}
