import type { PageTheme } from 'lib/page-props';

const THEME_CLASS_NAMES: Record<PageTheme, string> = {
	BlackFriday: 'bg-black text-white',
};

export function getPageThemeClasses(
	theme: PageTheme | undefined,
): string | undefined {
	return theme ? THEME_CLASS_NAMES[theme] : undefined;
}
