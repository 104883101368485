import React, {
	createContext,
	type ReactNode,
	useContext,
	useMemo,
} from 'react';
import { AnyInterpreter } from 'xstate';

import type { CheckoutMachineActor } from 'state-machines/checkout';

interface CheckoutContextType {
	checkoutService: CheckoutMachineActor;
}

export const CheckoutContext = createContext<CheckoutContextType>({} as any);

interface Props {
	checkoutService: AnyInterpreter;
	children: ReactNode;
}

export function CheckoutContextProvider({ checkoutService, children }: Props) {
	const checkoutContextValue: Record<
		keyof CheckoutContextType,
		AnyInterpreter
	> = useMemo(() => ({ checkoutService }), [checkoutService]);
	return (
		<CheckoutContext.Provider value={checkoutContextValue}>
			{children}
		</CheckoutContext.Provider>
	);
}

export function useCheckoutContext() {
	return useContext(CheckoutContext);
}
CheckoutContextProvider.displayName = 'CheckoutContextProvider';
