/**
 * Toast
 */

import React from 'react';
import { useMachine, useSelector } from '@xstate/react';
import clsx from 'clsx';

import InfoBox from 'components/InfoBox';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import { toastMachine, Variants } from 'state-machines/toast.machine';
import {
	selectIsMovingIn,
	selectIsMovingOut,
} from 'state-machines/toastItem.machine';
import { sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export const sendToast = (
	message: string,
	variant: Variants,
	link?: string,
	linkText?: string,
) => {
	sendGlobalEvent('toast', {
		message,
		variant,
		link,
		linkText,
	});
};

interface ToastItemProps {
	toastActor: any;
}

function ToastItem({ toastActor }: ToastItemProps) {
	const { message, variant, link, linkText } = toastActor.state.context;
	const isMovingIn = useSelector(toastActor, selectIsMovingIn);
	const isMovingOut = useSelector(toastActor, selectIsMovingOut);
	const key = `${message}-${variant}-${link}`;

	const { t } = useI18n();
	return (
		<InfoBox
			variant={variant}
			heading={t(message)}
			icon="info"
			link={link}
			linkText={linkText}
			// Screen readers can likely not access the toast so the
			// link text is useless for them.
			linkIsScreenReaderHidden
			key={key}
			type="toast"
			className={clsx(
				isMovingIn && 'max-sm:animate-moveInTop sm:animate-moveInRight',
				isMovingOut && 'animate-fadeOut',
			)}
		/>
	);
}
ToastItem.displayName = 'ToastItem';

/** Handles toast messages. */
export default function Toast() {
	const [current, send] = useMachine(toastMachine, {
		devTools: true,
	});

	return (
		<ScreenReaderAnnouncementText
			as="div"
			atomic
			className={clsx(
				'fixed left-0 right-0 top-0 z-toast space-y-4 sm:left-auto',
				current.context.toasts.length > 0 && 'sm:p-4',
			)}
			text={current.context.toasts.map((actor) => (
				<ToastItem key={actor.id} toastActor={actor} />
			))}
		/>
	);
}
Toast.displayName = 'Toast';
