import { useSitecoreContext as useSitecoreContextNative } from '@sitecore-jss/sitecore-jss-nextjs';

import { CustomLayoutServiceContext, RouteData } from 'lib/page-props';

/*
 * This is the same shape as the regular SitecoreContext
 * but with the addition of our custom LayoutServiceData and RouteData types.
 */
interface SitecoreContext extends CustomLayoutServiceContext {
	itemId?: string;
	route?: RouteData;
}

// Wrap the native hook to add more detailed typing.
export function useSitecoreContext() {
	const { sitecoreContext } = useSitecoreContextNative();
	return {
		sitecoreContext: sitecoreContext as unknown as SitecoreContext,
	};
}

export function useRouteFields() {
	const { sitecoreContext } = useSitecoreContext();
	return sitecoreContext.route?.fields;
}
