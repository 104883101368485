// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconError(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 22A10.01 10.01 0 0 1 2 12 10.01 10.01 0 0 1 12 2a10.01 10.01 0 0 1 10 10 10.01 10.01 0 0 1-10 10m0-6a1.5 1.5 0 0 0-1.5 1.5A1.5 1.5 0 0 0 12 19a1.5 1.5 0 0 0 1.5-1.5A1.5 1.5 0 0 0 12 16m0-11a1.5 1.5 0 0 0-1.5 1.5v6A1.5 1.5 0 0 0 12 14a1.5 1.5 0 0 0 1.5-1.5v-6A1.5 1.5 0 0 0 12 5" />
		</svg>
	);
}
IconError.displayName = 'IconError';
